.user-login-box {
  width: 400px;
  margin: 0px auto 150px auto;
  text-align: center;
}

.user-login-box img {
  margin: 0 auto 10px auto;
  width: 250px;
}

.user-login-box h1 {
  font-size: 35px;
  font-weight: bold;
  color: #333;
  margin-bottom: 40px;
}

.user-login-type {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.login-type-item {
  flex: 1;
  min-height: 80px;
  /* 버튼 높이를 일정하게 유지 */
  border: 1px solid #cacaca;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #686868;
  border-radius: 8px;
}

.login-type-item p {
  font-size: 11px;
  margin: 0;
  /* 기본 여백 제거 */
}

.user-loginform {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}

.user-loginform form {
  width: 100%;
  max-width: 500px;
}

.user-loginform input {
  width: 100%;
  height: 55px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 16px;
  margin-top: -1px;
}

.user-input-email {
  border-radius: 8px 8px 0 0;
}

.user-input-password {
  border-radius: 0 0 8px 8px;
}

/* 첫 번째 input에는 margin-top을 주지 않습니다 */
.user-loginform input:first-of-type {
  margin-top: 0;
}

.user-loginform input:focus {
  outline: none;
}

.user-loginform input::placeholder {
  color: #aaa;
}

.user-loginform label {
  display: none;
}

.user-login-btn {
  margin-top: 20px;
}

.user-login-btn button {
  width: 100%;
  height: 53px;
  padding: 15px;
  border: none;
  color: #ffffff;
  background-color: #ff85a1;
  cursor: pointer;
  border-radius: 8px;
}

.user-login-btn button:hover {
  border: none;
  color: #ffffff;
  background-color: rgba(255, 133, 161, 0.8);

}

.user-login-id-save {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-input-chk-left {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  color: #686868;
  line-height: 1.5;
}

.user-input-chk-left input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
  transform-origin: left;
  accent-color: #686868;
}

.user-txt-chk-right {
  display: flex;
  align-items: center;
}

.user-txt-chk-right a {
  font-size: 15px;
  font-weight: bold;
  color: #686868;
  margin-left: 10px;
  text-decoration: none;
  line-height: 1.5;
}

/* .user-txt-chk-right a:first-child::after,
.user-txt-chk-right a:nth-child(2)::after {
  content: " | ";
  color: #686868;
  font-weight: normal;
  margin-left: 10px;
  line-height: 1;
} */

/* 카카오 로그인 이미지 */
#kakaoIdLogin img {
  width: 100%;
  height: 55px;
  cursor: pointer;
  margin-bottom: 5px;
}

/* 네이버 로그인 버튼 */
#naverIdLogin {
  width: 100%;
  height: 55px;
  border-radius: 8px;
  background-color: #03c75a;
  padding: 0 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

#naverIdLogin button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #03c75a;
  border: none;
  cursor: pointer;
  position: relative;
  /* 추가: 자식 요소의 절대 위치 기준점 */
  padding: 0;
}

#naverIdLogin img {
  position: absolute;
  /* 추가: 왼쪽에 고정 */
  left: 0;
  height: 50px;
  width: auto;
  margin-top: 8px;
}

#naverIdLogin span {
  flex: 1;
  /* 추가: 남은 공간 전체 차지 */
  text-align: center;
  font-size: 19px;
  color: white;
  line-height: 1;
  padding-left: 25px;
  font-family: "Noto Sans KR", sans-serif;
}

/* 구글 로그인 버튼 */
#googleIdLogin {
  width: 100%;
  height: 55px;
  border-radius: 8px;
  border: 1px solid #333;
  background-color: white;
  padding: 0 5px;
  box-sizing: border-box;
}

#googleIdLogin button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  border: none;
  cursor: pointer;
  position: relative;
  /* 추가: 자식 요소의 절대 위치 기준점 */
  padding: 0;
}

#googleIdLogin img {
  position: absolute;
  /* 추가: 왼쪽에 고정 */
  left: 0;
  height: 50px;
  width: auto;
  margin-top: 6px;
}

#googleIdLogin span {
  flex: 1;
  /* 추가: 남은 공간 전체 차지 */
  text-align: center;
  font-size: 19px;
  color: #333;
  line-height: 1;
  padding-left: 25px;
  font-family: "Noto Sans KR", sans-serif;
}

/* 모바일 스타일 */
@media screen and (max-width: 768px) {
  .user-login-box {
    margin-top: 20px;
    padding: 15px;
    width: 90%;
  }

  .user-loginform input {
    font-size: 14px;
    padding: 8px;
  }

  .user-login-btn button {
    font-size: 14px;
    padding: 10px;
  }

  .user-login-id-save {
    flex-direction: column;
    align-items: flex-start;
  }

  .user-login-id-save .user-txt-chk-right {
    margin-top: 10px;
  }

  #user-login-list {
    display: none;
    /* 모바일에서는 숨김 */
  }

  footer {
    margin-top: 20px;
    padding: 10px;
    font-size: 12px;
  }
}