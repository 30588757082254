@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR&display=swap');

.text-center .vender-header-wrap {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.sd-logo-container {
    width: 100%;
    text-align: center;
    padding: 20px 0;
    background: #fff;
}

.sd-store-logo {
    max-width: 200px;
    height: auto;
}

.sd-profile-header {
    display: flex;
    /* 플렉스박스 사용 */
    align-items: center;
    /* 수직 중앙 정렬 */
    justify-content: center;
    /* 수평 중앙 정렬 */
    position: relative;
    /* 상대 위치 지정 */
    padding: 20px;
}

.sd-profile-container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'IBM Plex Sans KR', sans-serif;
}

.sd-store-name {
    font-size: 40px;
    font-weight: bold;
    margin: 0;
    text-align: center;
    flex-grow: 1;
    /* 이름을 중앙에 배치 */
}

.sd-profile-content {
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
}

/* 각 섹션 공통 스타일 */
.sd-section {
    flex: 1;
    border-radius: 10px;
}

/* 이미지 섹션 */
.sd-image-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.sd-profile-image {
    width: 80%;
    height: 200px;
    display: grid;
    /* 내부 이미지 정렬을 위한 Grid */
    place-items: center;
    /* 내부 이미지 가로와 세로 중앙 정렬 */
}

.sd-profile-image img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* 이미지 비율 유지 */
    background-position: center;
    /* 이미지 중앙 */
}

/* 정보 섹션 */
.sd-info-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.sd-profile-stats {
    display: flex;
    align-items: center;
    font-size: 18px;
    gap: 20px;
    margin-bottom: 15px;
}

.sd-profile-stats span {
    color: #333;
}

.sd-info-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 18px;
    font-family: 'IBM Plex Sans KR', sans-serif;
}

.sd-info-content p {
    margin: 0;
    font-size: 18px;
    line-height: 1.6;
    color: #666;
}

/* 지도 섹션 */
.sd-map-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.sd-map-container {
    width: 100%;
    aspect-ratio: 1;
    background: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;
}

.map-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #eee;
    color: white;
}

.sd-map-info {
    padding: 15px;
    background: #3f3f3f;
    border-radius: 8px;
    font-size: 18px;
    color: white;
}

.sd-map-info p {
    margin: 5px 0;
    font-size: 18px;
    color: white;
}

.sd-map-title {
    font-weight: bold;
    margin-bottom: 10px !important;
    color: white;
}

/* 카카오톡 버튼 */
.kakao-chat-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #FEE500;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    color: #000000;
    cursor: pointer;
    transition: all 0.2s ease;
}

.kakao-chat-btn:hover {
    background-color: #FDD835;
    transform: translateY(-1px);
}

.kakao-chat-btn .kakao-icon {
    width: 20px;
    height: 20px;
}

/* 구분선 */
.sd-divider {
    border: none;
    border-top: 1px solid #e5e7eb;
    margin: 20px auto;
    max-width: 1200px;
}

/* 카테고리 */
.sd-category-container {
    display: flex;
    justify-content: row;
    gap: 20px;
    padding: 10px 0;
    max-width: 1200px;
    margin: 0 auto;
}

.sd-category-item {
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 10px;
    border-radius: 10px;
    font-family: 'IBM Plex Sans KR', sans-serif;
    margin-bottom: 20px;
}

.sd-category-item img {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.sd-category-item p {
    font-size: 1rem;
    color: #333;
    margin: 0;
    font-family: 'IBM Plex Sans KR', sans-serif;
}



.sd-category-item.active p {
    color: #ff6f61;
    font-weight: bold;
}

/* 상품 리스트 */
.sd-products-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1px;
    padding: 1px;
    max-width: 1200px;
    min-height: 550px;
    margin: 0 auto;

}

.sd-products-containerTwo {
    background-image: url(../../../images/exListIMG.png);
    background-size: cover;
    overflow: hidden;
    background-position: center;
}

.sd-product-item {
    position: relative;
    background: #fff;
    width: 270px;
    aspect-ratio: 1;
    text-decoration: none;
    transition: transform 0.2s ease;
    border-radius: 10px;
}

.sd-product-item:hover {
    transform: translateY(-2px);
}

.sd-product-image {
    width: 100%;
    height: calc(100% - 50px);
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.sd-product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sd-price-info {
    padding: 8px;
    text-align: left;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
}

.sd-product-name {
    font-size: 18px;
    color: #333;
    font-weight: bolder;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 3px;
    font-family: 'IBM Plex Sans KR', sans-serif;
}

.sd-price {
    font-size: 18px;
    color: #333;
    margin: 2px 0 0 0;
    font-weight: bold;
}

.h-storeinfo {
    font-family: 'IBM Plex Sans KR', sans-serif;
}

.auth-actions {
    position: absolute;
    /* 오른쪽에 고정 */
    right: 20px;
    /* 오른쪽 여백 */
    top: 50%;
    /* 수직 중앙 정렬 */
    transform: translateY(-50%);
    display: flex;
    gap: 12px;
    /* 버튼 간 간격 */
    
}

.auth-link,
.auth-link-button {
    display: inline-block;
    padding: 10px 20px;
    border: none; /* 테두리 제거 */
    border-radius: 12px; /* 부드러운 모서리 */
    background-color: #3f3f3f; /* 기본 배경색 */
    color: #ffffff; /* 밝은 텍스트 색상 */
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    text-decoration: none; /* 링크 밑줄 제거 */
    transition: all 0.3s ease; /* 부드러운 효과 */
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 약간의 그림자 */
}

.auth-link:hover,
.auth-link-button:hover {
    background-color: #5f5f5f; /* 호버 시 밝아진 배경색 */
    color: #ffffff; /* 텍스트 색상 유지 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 강조된 그림자 */
}

.auth-link-button {
    background-color: #2f2f2f; /* 로그아웃 버튼: 더 어두운 배경 */
    color: #ffffff; /* 흰색 텍스트 */
}

.auth-link-button:hover {
    background-color: #4f4f4f; /* 로그아웃 버튼 호버 색상 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 강조된 그림자 */
}

.floating-back-button {
    position: fixed;
    /* 화면에 고정 */
    bottom: 20px;
    /* 화면 하단에서 20px */
    right: 130px;
    /* 화면 오른쪽에서 20px */
    background-color: #ff6f61;
    /* 버튼 배경색 */
    color: #fff;
    /* 버튼 텍스트 색 */
    border: none;
    border-radius: 50px;
    /* 둥근 버튼 */
    padding: 12px 20px;
    font-size: 14px;
    font-family: 'IBM Plex Sans KR', sans-serif;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    /* 그림자 효과 */
    transition: transform 0.3s ease, background-color 0.3s ease;
    z-index: 1000;
    /* 다른 요소 위에 표시 */
    animation: floating 3s ease-in-out infinite;
    /* 떠다니는 애니메이션 */
}

/* 호버 효과 */
.floating-back-button:hover {
    background-color: #e05a4f;
    /* 호버 시 배경색 변경 */
    transform: scale(1.1);
    /* 호버 시 확대 효과 */
}

/* 클릭 효과 */
.floating-back-button:active {
    transform: scale(0.95);
    /* 클릭 시 축소 효과 */
}

/* 떠다니는 애니메이션 */
@keyframes floating {
    0% {
        transform: translateY(0px);
        /* 기본 위치 */
    }

    50% {
        transform: translateY(-10px);
        /* 위로 이동 */
    }

    100% {
        transform: translateY(0px);
        /* 원래 위치로 돌아옴 */
    }
}


@media screen and (max-width: 768px) {

    /* 프로필 섹션 */
    .sd-profile-container {
        display: flex;
        flex-direction: column;
        /* 세로 배치 */
        align-items: center;
        padding: 10px;
        width: 100%;
        /* 전체 화면 너비 사용 */
    }

    .sd-profile-content {
        display: flex;
        flex-direction: column;
        /* 세로 배치 */
        gap: 15px;
        align-items: center;
        width: 100%;
        /* 너비 조정 */
    }

    .sd-profile-image {
        width: 100px;
        /* 이미지 크기 축소 */
        height: 100px;
    }

    .sd-profile-stats {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        /* 통계를 여러 줄로 배치 */
        font-size: 14px;
        gap: 10px;
    }

    .sd-info-content {
        font-size: 14px;
        /* 텍스트 크기 축소 */
        text-align: center;
        /* 텍스트 중앙 정렬 */
    }

    /* 지도 섹션 */
    .sd-map-section {
        gap: 10px;
        padding: 10px;
        width: 100%;
        /* 전체 화면 너비 사용 */
    }

    .sd-map-container {
        width: 100%;
        aspect-ratio: 16 / 9;
        /* 지도 비율 변경 */
        border-radius: 10px;
        background-color: #eaeaea;
        /* 지도 배경색 추가 */
    }

    .sd-map-info {
        font-size: 12px;
        /* 텍스트 크기 축소 */
        text-align: center;
        /* 텍스트 중앙 정렬 */
    }

    /* 카테고리 섹션 */
    .sd-category-container {
        display: flex;
        flex-wrap: wrap;
        /* 여러 줄로 배치 */
        justify-content: center;
        /* 중앙 정렬 */
        gap: 10px;
        padding: 10px;
        width: 100%;
        /* 전체 화면 너비 사용 */
    }

    .sd-category-item {
        flex: 0 1 30%;
        /* 너비를 30%로 설정 */
        text-align: center;
        padding: 5px;
    }

    .sd-category-item img {
        width: 40px;
        height: 40px;
        /* 이미지 크기 축소 */
        margin-bottom: 5px;
    }

    .sd-category-item p {
        font-size: 12px;
        /* 텍스트 크기 축소 */
    }

    /* 상품 리스트 */
    .sd-products-container {
        grid-template-columns: repeat(2, 1fr);
        /* 한 줄에 2개씩 배치 */
        gap: 15px;
        /* 간격 축소 */
        padding: 8px;
        width: 100%;
    }

    .sd-product-item {
        width: 150px;
        /* 전체 너비 사용 */
        height: 200px;
        /* 전체 너비 사용 */
        margin: 0 auto;
        /* 중앙 정렬 */
        border-radius: 10px;
    }

    .sd-product-image {
        width: 100%;
        height: auto;
        border-radius: 8px;
        /* 둥근 모서리 */
        overflow: hidden;
    }

    .sd-product-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* 비율 유지하며 채우기 */
    }

    .sd-price-info {
        font-size: 12px;
        /* 텍스트 크기 축소 */
        padding: 8px;
        text-align: center;
        /* 중앙 정렬 */
    }

    .sd-product-name {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .sd-price {
        font-size: 14px;
        font-weight: bold;
    }

    /* 카카오톡 버튼 */
    .kakao-chat-btn {
        width: 90%;
        /* 너비 확장 */
        font-size: 14px;
        /* 텍스트 크기 축소 */
        margin: 10px auto;
        /* 중앙 정렬 */
        padding: 10px;
    }

    /* 텍스트 센터 섹션 */
    .text-center .vender-header-wrap {
        padding: 10px;
    }

    .sd-store-name {
        font-size: 1.5rem;
        /* 텍스트 크기 축소 */
        text-align: center;
        /* 중앙 정렬 */
    }
}