#form-group-img-box .add-image-btn {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px 12px;
    background-color: #4f46e5;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.9em;
}

/* 미리보기 이미지 컨테이너 */
.cake-design-imgs {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 3열 그리드 설정 */
    gap: 10px; /* 이미지 간격 */
    margin-bottom: 20px;
    padding-left: 50px;
}

/* 미리보기 이미지 스타일 */
.cake-design-imgs img {
    width: 100%; /* 이미지가 그리드 셀을 채우도록 설정 */
    max-width: 150px; /* 각 이미지의 최대 너비 설정 */
    height: 150px;
    margin-bottom: 10px;
    display: block;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


/* 파일 입력 필드 스타일 */
.file-input-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.file-input-wrapper input[type="file"] {
    flex: 1;
    padding: 8px;
    font-size: 0.9em;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
}

.remove-button {
    margin-left: 10px;
    padding: 8px 12px;
    background-color: #e74c3c;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.9em;
}

.remove-button:hover {
    background-color: #c0392b;
}

/* 이미지 추가 버튼 */
.add-image-button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #4f46e5;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.9em;
}

.add-image-button:hover {
    background-color: #3b37c1;
}

/* 파일 입력 필드 스타일 */
.file-input-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.file-input-wrapper input[type="file"] {
    flex: 1;
    padding: 12px;
    font-size: 0.9em;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
}
