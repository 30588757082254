/* Modal Container */
.j-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.j-modal-content {
  background: #fff;
  padding: 30px 40px 40px 40px;
  width: 90%;
  max-width: 1000px;
  border-radius: 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  position: relative;
}

.j-modal-content h2 {
  font-size: 1.3em;
  padding-bottom: 20px;
}

/* Close Button */
.j-modal-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #666;
  transition: all 0.3s ease;
}

/* Tab Container */
.j-tab-container {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  /* border-bottom: 1px solid #c8c8c8; */
  padding-bottom: 10px;
}

/* Tabs */
.j-tab {
  font-size: 1.1em;
  padding: 12px 24px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: #666;
  transition: all 0.3s ease;
  position: relative;
}

.j-tab.active {
  font-weight: 600;
  color: #ff85a1;
}

.j-tab.active::after {
  content: "";
  position: absolute;
  bottom: -11px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ff85a1;
  border-radius: 2px;
}

/* Search and Dropdown Container */
.j-search-category-container {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
}

/* Category Dropdown */
.j-category-select {
  padding: 12px 20px;
  font-size: 0.95em;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background-color: #fafafa;
  color: #444;
  transition: all 0.3s ease;
  min-width: 150px;
}

.j-category-select:focus {
  outline: none;
  border-color: #ff85a1;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(255, 133, 161, 0.1);
}

/* Search Bar */
.j-search-bar {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}

.j-search-input {
  flex: 1;
  padding: 10px 15px;
  height: 43px;
  border: 1px solid #c8c8c8;
  border-right: none;
  border-radius: 20px 0 0 20px;
  font-size: 14px;
  outline: none;
}

.j-search-input::placeholder {
  color: #bbbbbb; /* 원하는 색상 코드 */
  opacity: 1; /* 불투명도 설정 (기본값이 0.5일 수 있음) */
}

.j-search-button {
  padding: 10px 20px;
  height: 43px;
  background-color: white;
  border: 1px solid #c8c8c8;
  border-left: none; /* 왼쪽 테두리 제거 */
  border-radius: 0 25px 25px 0; /* 순서: 좌상 우상 우하 좌하 */
  color: #999999;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Product Grid */
.j-products-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.j-product-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
  text-align: center;
  border: 1px solid #c8c8c8;
}

.j-product-card:hover {
  transform: translateY(-1px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

/* Fixed Image Size */
.j-product-image {
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  padding: 15px;
}

.j-product-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Product Info */
.j-product-info {
  padding: 15px;
  background: white;
}

.j-product-info h3 {
  font-size: 1em;
  color: #333;
  margin: 0;
  line-height: 1.4;
}

/* Pagination */
.j-pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 40px;
}

.j-page-number,
.j-prev-page,
.j-next-page {
  padding: 8px 12px;
  border: none;
  background: white;
  font-size: 17px;
  color: #666;
  cursor: pointer;
}

.j-page-number,
.j-prev-page,
.j-next-page {
  border-color: #ff85a1;
  color: #ff85a1;
}

.j-page-number.active {
  color: #ff85a1;
  font-weight: bold;
}

.appeal-design-text-jw {
  margin-top: 30px;
}

.appeal-design-text-jw ul {
  padding-left: 20px;
}

.appeal-design-text-jw ul li:not(:first-child) {
  padding-top: 15px;
}

.orderTitle {
  color: #333;
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: 10px;
}
