#user-wrap .order-complete-container {
  max-width: 500px;
  margin: 40px auto;
  text-align: center;
  padding: 40px 20px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid #d1d1d1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: 'IBM Plex Sans KR', sans-serif;
}

#user-wrap .order-complete-container .order-complete-icon {
  margin-bottom: 24px;
}

#user-wrap .order-complete-container .check-circle {
  width: 80px;
  height: 80px;
  background-color: #3f3f3f;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#user-wrap .order-complete-container .check {
  color: white;
  font-size: 40px;
}

#user-wrap .order-complete-container .order-complete-message {
  margin-bottom: 32px;
}

#user-wrap .order-complete-container .order-complete-message h2 {
  font-size: 27px;
  color: #333;
  margin-bottom: 12px;
}

#user-wrap .order-complete-container .order-complete-message p {
  color: #666;
  font-size: 20px;
}

#user-wrap .order-complete-container .order-info {
  background-color: #f8f9fa;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 32px;
}

#user-wrap .order-complete-container .info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  color: #333;
}

#user-wrap .order-complete-container .info-row:last-child {
  margin-bottom: 0;
}

#user-wrap .order-complete-container .label {
  color: #666;
  font-size: 1.1em;
}

#user-wrap .order-complete-container .value {
  font-weight: bold;
  font-size: 1.1em;
}

#user-wrap .order-complete-container .button-container {
  margin-top: 40px;
}

#user-wrap .order-complete-container .main-button {
  padding: 15px 30px;
  font-size: 17px;
  background-color: #848484;
  color: #ffffff;
  border: 1px solid #848484;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#user-wrap .order-complete-container .main-button:hover {
  border: none;
  transform: scale(1.02);
  background-color: #333;
}

@media (max-width: 768px) {
  #user-wrap .order-complete-container {
    margin: 20px auto;
    padding: 30px 15px;
  }

  #user-wrap .order-complete-container .check-circle {
    width: 60px;
    height: 60px;
  }

  #user-wrap .order-complete-container .check {
    font-size: 30px;
  }

  #user-wrap .order-complete-container .order-complete-message h2 {
    font-size: 20px;
  }
}
