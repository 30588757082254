/* Modal Overlay */
.withdraw-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.withdraw-modal-content {
  background: #fff;
  padding: 30px;
  width: 400px;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.withdraw-modal-content h2 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

.withdraw-subtitle {
  color: #EA4335;
  font-size: 0.95rem;
  margin-bottom: 20px;
}

.withdraw-info-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: left;
  border: 1px solid #ffe9ee;
}

.withdraw-info-box h3 {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 15px;
  font-weight: bold;
}

.withdraw-info-box ul {
  list-style: none;
  padding: 0;
  font-size: 0.9rem;
  color: #666;
}

.withdraw-info-box li {
  margin-bottom: 12px;
  line-height: 1.5;
  position: relative;
  padding-left: 15px;
}

.withdraw-info-box li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #EA4335;
}

/* Buttons */
.withdraw-confirm-btn {
  background-color: #EA4335;
  color: #fff;
  padding: 12px 25px;
  border: 1px solid #FF7292;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: bold;
  transition: all 0.3s ease;
}

.withdraw-cancel-btn {
  background-color: #fff;
  color: #666;
  padding: 12px 25px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: bold;
  margin-left: 10px;
  transition: all 0.3s ease;
}

.withdraw-cancel-btn:hover {
  background-color: #f8f8f8;
}