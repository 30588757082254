@charset "UTF-8";

.vender-header {
    margin-bottom: 15px;
    margin-top: 10px;
}

.vender-header-wrap {
    width: 100%;
    max-width: 1700px;
    height: 250px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    justify-content: center; /* 가로 중앙 정렬 */
    align-items: center; /* 세로 중앙 정렬 */
}


.vender-header-wrap img {
    max-width: 100%; /* 가로 크기 제한 */
    max-height: 100%; /* 세로 크기 제한 */
    object-fit: contain; /* 이미지 비율 유지하며 컨테이너에 맞게 조정 */
    object-position: center; /* 이미지 중심 기준으로 배치 */
}

.vender-header-mainlogo {
    position: relative;
    /* 자식 요소들을 절대 위치로 배치할 수 있도록 함 */
}

.vender-header-icon {
    position: absolute;
    /* 아이콘 위치 고정 */
    bottom: 10px;
    /* 하단 여백 */
    right: 10px;
    /* 오른쪽 여백 */
    font-size: 30px;
    color: gray;
}

.vender-header-icon-box {
    text-align: right;
    padding: 0 10px 15px 0;
}