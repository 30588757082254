/* Status Overview Styles */
#user-wrap .order-status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f0f0f0;
    padding: 24px;
    margin: 30px 0;
    gap: 10px;
}

#user-wrap .status-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
  padding: 0 20px;
}

#user-wrap .status-count {
  font-size: 1.8em;           /* 변경 */
  font-weight: bold;
  color: var(--main-pink);
  margin-bottom: 8px;
}

/* 포인트 조회 페이지 스타일 */
.point-label {
  color: #333;
  padding-bottom: 5px;
}

.point-order-list {
  width: 100%;
  margin: 20px 0;
}

.point-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* 테이블 헤더 스타일링 */
.point-table thead {
  background-color: #e7e7e7;
}

.point-table th {
  padding: 15px;
  text-align: center;
  font-weight: 500;
  color: #4b5563;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

/* 테이블 바디 스타일링 */
.point-table td {
  padding: 12px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: #666;
}

/* 포인트 금액 스타일링 */
.point-amount {
  color: #ff4081;
  font-weight: 500;
}

/* 로딩, 에러, 빈 상태 메시지 스타일링 */
.point-loading,
.point-error,
.point-empty {
  text-align: center;
  padding: 30px !important;
}


/* 반응형 디자인을 위한 미디어 쿼리 */
@media screen and (max-width: 768px) {
  .point-table {
    font-size: 14px;
  }

  .point-table th,
  .point-table td {
    padding: 10px 8px;
  }
}
