@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR&display=swap');

/* 레이아웃 */
.option-selection-area {
    display: flex;
    width: 100%;
    height: 100vh;
}

.option-selection-area .left-panel,
.option-selection-area .right-panel {
    width: 50%;
    padding: 20px;
    overflow-y: auto;
    box-sizing: border-box;
}

.option-selection-area .right-panel {
    border-left: 1px solid #444444; /* 다크 테두리 */
    background-color: #313131; /* 다크 배경 */
}

/* 옵션 리스트 영역 */
.option-selection-area .option-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* 옵션 아이템 스타일 */
.option-selection-area .option-item {
    background-color: #424242; /* 다크 배경 */
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* 다크 테마에 어울리는 그림자 */
    transition: background-color 0.3s, box-shadow 0.3s;
    cursor: pointer;
}

.option-selection-area .option-item:hover {
    background-color: #505050; /* hover 배경 */
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.4);
}

/* 옵션 헤더 */
.option-selection-area .option-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 1.2em;
    color: #ffffff; /* 흰색 텍스트 */
}

/* 옵션 세부 사항 */
.option-selection-area .option-details {
    padding: 10px;
    margin-top: 10px;
    background-color: #505050; /* 다크 배경 */
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

/* 서브 옵션 아이템 */
.option-selection-area .option-sub-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 8px;
    background-color: #424242; /* 서브 옵션 배경 */
    border-radius: 4px;
}

.option-selection-area .sub-option-image {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 10px;
    object-fit: cover;
}

.option-selection-area .sub-option-name {
    flex: 1;
    font-size: 1em;
    color: #ffffff; /* 흰색 텍스트 */
    margin-right: 5px;
}

/* 추가 옵션 입력 */
.option-selection-area .add-sub-option {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

/* 파일 업로드 버튼 및 미리보기 */
.option-selection-area .input-group {
    position: relative;
    margin-right: 20px;
}

.option-selection-area .upload-label {
    display: inline-block;
    cursor: pointer;
    padding: 8px;
    background-color: #505050; /* 다크 배경 */
    border: 1px solid #666666; /* 테두리 */
    border-radius: 4px;
    width: 100px;
    text-align: center;
    transition: background-color 0.3s;
    color: #ffffff; /* 흰색 텍스트 */
}

.option-selection-area .upload-label:hover {
    background-color: #424242; /* hover 배경 */
}

.option-selection-area .upload-placeholder {
    color: #aaaaaa; /* 연한 회색 텍스트 */
    font-size: 14px;
}

.option-selection-area .preview-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 4px;
    object-fit: cover;
}

.option-selection-area .input-file {
    display: none;
}

/* 텍스트 입력 */
.option-selection-area .input-text {
    padding: 5px;
    width: 150px;
    border: 1px solid #666666;
    border-radius: 4px;
    outline: none;
    font-size: 1em;
    background-color: #313131; /* 다크 배경 */
    color: #ffffff; /* 흰색 텍스트 */
}

/* 등록 버튼 */
.option-selection-area .add-button {
    background-color: #00c853; /* 강조 색상 */
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
    margin-left: auto;
}

.option-selection-area .add-button:hover {
    background-color: #009624; /* hover 색상 */
    box-shadow: 0 4px 12px rgba(0, 200, 83, 0.4);
    transform: translateY(-3px);
}

/* 삭제 버튼 */
.option-selection-area .delete-button {
    background-color: #f87171;
    color: #ffffff;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.2s;
    margin-left: 5px;
}

.option-selection-area .delete-button:hover {
    background-color: #e11d48;
    transform: translateY(-2px);
}

/* 오른쪽 패널 헤더 */
.option-selection-area .right-panel h3 {
    font-size: 1.5em;
    color: #ffffff; /* 흰색 텍스트 */
    margin-bottom: 20px;
}

.option-selection-area .selected-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* 오른쪽 패널의 옵션 그룹 스타일 */
.option-selection-area .selected-option-group {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #424242; /* 다크 배경 */
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.option-selection-area .option-group-title {
    font-size: 1.2em;
    color: #00c853; /* 강조 색상 */
    margin-bottom: 10px;
    font-weight: bold;
}

/* 템플릿 리스트 스타일 */
.option-selection-area .template-list {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.option-selection-area .template-button {
    width: 100%;
    padding: 8px 16px;
    background-color: #6b7280; /* 다크 회색 */
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px;
}

.option-selection-area .template-button:hover {
    background-color: #4b5563; /* hover 색상 */
}

.option-selection-area .right-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.option-selection-area .register-product-button {
    background-color: #00c853; /* 강조 색상 */
    color: #ffffff;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
    margin-left: 10px;
}

.option-selection-area .register-product-button:hover {
    background-color: #009624;
    box-shadow: 0 4px 12px rgba(0, 200, 83, 0.4);
    transform: translateY(-3px);
}
