/* 전체 레이아웃 */
.admin-companylist-content {
    padding: 20px;
    background-color: #f5f5f7; /* 애플 느낌의 밝은 회색 */
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    color: #1d1d1f; /* 어두운 회색 */
}

.admin-companylist-content h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #1d1d1f;
    font-weight: 600;
}

/* 필터 섹션 */
.admin-companylist-content .filter-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}

.admin-companylist-content .map-placeholder {
    flex: 1;
    height: 300px;
    background-color: #d1d1d6; /* 밝은 회색 */
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 14px;
}
.admin-companylist-content .map-message {
    font-size: 24px;
    color: #666;
}
/* 지역 필터 */
.admin-companylist-content .region-filter {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.admin-companylist-content .region-buttons, .district-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
}

.admin-companylist-content .region-button, .district-button {
    padding: 10px 15px;
    border: 1px solid #d1d1d6;
    border-radius: 8px;
    background-color: #ffffff;
    color: #1d1d1f;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
}

.admin-companylist-content .region-button.active, .district-button.active {
    background-color: #007aff; /* 애플 파란색 */
    color: #ffffff;
}

.admin-companylist-content .region-button:hover, .district-button:hover {
    background-color: #e5e5ea; /* 밝은 회색 */
}

/* 검색 섹션 */
.admin-companylist-content .search-section {
    display: flex;
    gap: 10px;
    align-items: center;
}

.admin-companylist-content .search-section input {
    flex: 1;
    padding: 10px;
    border: 1px solid #d1d1d6;
    border-radius: 8px;
    font-size: 14px;
    background-color: #ffffff;
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.admin-companylist-content .search-section input:focus {
    box-shadow: 0 0 8px rgba(0, 122, 255, 0.3);
    border-color: #007aff;
}

.admin-companylist-content .search-section button {
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    background-color: #007aff;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-companylist-content .search-section button:hover {
    background-color: #005fcb;
}

/* 업체 테이블 */
.admin-companylist-content .company-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 12px;
    overflow: hidden;
}

.admin-companylist-content .company-table th,.admin-companylist-content .company-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #e5e7eb;
}

.admin-companylist-content .company-table th {
    background-color: #f1f5f9;
    font-weight: 600;
    color: #333;
}

.admin-companylist-content .company-table tr:hover {
    background-color: #f9fafb;
}

/* 페이징 버튼 */
.admin-companylist-content .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.admin-companylist-content .pagination-button {
    padding: 10px 15px;
    border: 1px solid #d1d1d6;
    border-radius: 8px;
    background-color: #f5f5f7;
    color: #1d1d1f;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-companylist-content .pagination-button.active {
    background-color: #007aff;
    color: #ffffff;
    border-color: #007aff;
}

.admin-companylist-content .pagination-button:hover {
    background-color: #e5e5ea;
}


.admin-companylist-content .clickable-address:hover {
    cursor: pointer;
}