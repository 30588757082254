/* General Layout */
.debate-view-container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
}

/* Header Section */
.debate-view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 10px;
  position: relative;
}

.debate-view-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #333, #333);
}

.user-debate-title {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #333;
}

.user-debate-title span {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
}

.debate-meta-info {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 15px;
  color: #666;
}

.debate-meta-info p {
  display: flex;
  align-items: center;
  gap: 6px;
}

/* Image and Progress Bar Section */
.debate-images-progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  padding: 30px;
  background-color: white;
  border-radius: 20px;
}

.debate-image-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-bottom: 30px;
}

.debate-image-container {
  position: relative;
  text-align: center;
}

.debate-image-container img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

/* Vote Buttons */
.vote-button {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.vote-button-a {
  background-color: #5dadec;
}

.vote-button-b {
  background-color: #a593e0;
}

.vote-button:hover {
  transform: translateX(-50%) translateY(-2px);
}

.vs-text {
  font-size: 28px;
  color: #ff85a1;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Progress Bar */
/* Progress Bar */
.vote-progress-bar {
  width: 100%;
  max-width: 650px;
  height: 36px;
  background-color: #f0f0f0;
  border-radius: 18px;
  overflow: hidden;
  margin: 20px auto;
  display: flex; /* 이 부분이 중요합니다 */
}

.left-bar,
.right-bar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 14px;
  transition: width 0.3s ease;
  /* width는 React에서 동적으로 설정됩니다 */
}

.left-bar {
  background-color: #5dadec;
}

.right-bar {
  background-color: #a593e0;
}

/* Description Section */
.debate-description {
  margin: 40px auto;
  padding: 30px;
  background-color: #f1f1f1;
  border-radius: 20px;
}

.debate-description h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.debate-description p {
  white-space: pre-line;
  font-size: 20px;
  color: #444;
  line-height: 1.8;
  min-height: 100px;
}

/* Comments Section */
.comments-section {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #f0f0f0;
}

.comments-section h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 25px;
}

/* Comment Input Area */
.comment-input-wrapper {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
}

.comment-textarea-container {
  flex-grow: 1;
}

.comment-textarea-container textarea {
  width: 100%;
  min-height: 100px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 12px;
  font-size: 14px;
  line-height: 1.6;
  resize: vertical;
  transition: all 0.3s ease;
  background-color: #fafafa;
}

.comment-textarea-container textarea:focus {
  outline: none;
  border-color: #ff85a1;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(255, 133, 161, 0.1);
}

.comment-submit-container {
  padding-top: 23px;
}

.submit-comment {
  background-color: #ff85a1;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  min-width: 100px;
  transition: all 0.3s ease;
}

/* Comment List */
.comment-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.comment {
  display: flex;
  gap: 15px;
  padding: 15px 0;
  background-color: white;
  transition: transform 0.3s ease;
  border-bottom: 1px solid #ddd;
}

.comment-profile-pic img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.comment-content {
  flex: 1;
}

.comment-content p {
  font-size: 1.05em;
}

.comment-author-vote {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
}

.comment-author {
  font-weight: 600;
  color: #333;
  font-size: 1.1em;
}

.user-vote-indicator {
  font-size: 13px;
  color: #666;
  background-color: #fff;
  padding: 4px 12px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Edit/Delete Buttons */
.user-debate-edit-button,
.user-debate-delete-button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.user-debate-edit-button {
  color: #666;
  background-color: #fff;
  border: 1px solid #e0e0e0;
}

.user-debate-delete-button {
  color: #666;
  background-color: #fff;
  border: 1px solid #e0e0e0;
}

.user-debate-edit-button:hover {
  color: white;
  background-color: #ff85a1;
  border-color: #ff85a1;
}

.user-debate-delete-button:hover {
  color: white;
  background-color: #ff4b4b;
  border-color: #ff4b4b;
}
