/* catWalker.css */

/* 고양이 컨테이너 */
.cat-container {
    position: fixed;
    top: 12%;
    right: 50px;
    width: 200px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1000; /* 다른 요소 위에 고양이 표시 */
}

/* 고양이 이미지 애니메이션 */
.moving-cat {
    width: 100%;
    height: auto;
    animation: moveUpAndDown 4s ease-in-out infinite alternate;
}

@media (max-width: 1650px) {
    .cat-container {
        display: none; /* 1200px 이하에서는 숨김 */
    }
}
