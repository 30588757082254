@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&family=Orbit&display=swap');
/*admin-container */

/* 국민연금체 */
@font-face {
    font-family: 'NPSfontBold';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2310@1.0/NPSfontBold.woff2') format('woff2');
    /* font-weight: 700; */
    font-style: normal;
}

.admin-sidebar {
    width: 240px;
    background-color: #f5f5f7;
    /* 애플 느낌의 밝은 회색 */
    color: #333333;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid #d6d6d6;
    /* 섬세한 경계선 */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* 약간의 그림자 */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    margin-right: 20px;
    height: 870px;
}

.admin-sidebar .logo {
    font-family: 'NPSfontBold';
    font-size: 1.8em;
    font-weight: 600;
    margin: 0;
    color: #000000;
    /* 검정색으로 세련된 느낌 */
    letter-spacing: -0.02em;
    margin-bottom: 20px;
}

.admin-sidebar .menu {
    list-style: none;
    padding: 0;
    margin-top: 20px;
    width: 100%;
}

.admin-sidebar .menu li {
    margin: 10px 0;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.admin-sidebar .menu li a {
    color: #555555;
    /* 중간 회색 */
    text-decoration: none;
    display: block;
    padding: 12px 15px;
    border-radius: 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.admin-sidebar .menu li:hover a {
    background-color: #e0e0e0;
    /* 살짝 더 어두운 배경 */
    color: #000000;
}

.admin-sidebar .menu li.active a {
    background-color: #d6d6d6;
    /* 선택된 메뉴 색상 */
    color: #000000;
}

.admin-sidebar .admin-exit-button {
    background-color: #ff3b30;
    /* 애플의 빨간색 버튼 느낌 */
    color: #ffffff;
    border: none;
    border-radius: 10px;
    padding: 12px 15px;
    margin-top: auto;
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-sidebar .admin-exit-button:hover {
    background-color: #ff453a;
    /* 더 밝은 빨간색 */
}

/* 메뉴 구분선 */
.admin-sidebar .menu-divider {
    border: none;
    border-top: 1px solid #d6d6d6;
    margin: 15px 0;
    width: 100%;
}