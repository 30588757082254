@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR&display=swap');

.vender-order-list-main-content {
    flex-grow: 1;
    background-color: #ffffff;
    border-radius: 16px; /* 큰 라운드를 적용하여 부드러운 모서리 */
    padding: 24px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06); /* 더 부드러운 그림자 */
    transition: transform 0.3s;
}

.vender-order-list-main-content  .order-list-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.vender-order-list-main-content  .order-list-header h2 {
    font-size: 2em;
    /* 크고 굵은 글씨로 제목 강조 */
    font-weight: 600;
    color: #222;
    /* 더 진한 색상으로 강조 */
}

.vender-order-list-main-content  .vender-filter-section {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: right;
}

.vender-order-list-main-content  .vender-filter-section label {
    margin-right: 10px;
    font-weight: bold;
    font-size: 16px;
}

.vender-order-list-main-content  .vender-filter-section select {
    padding: 1px 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    width: 160px;
}

.vender-order-list-main-content  .vender-order-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 12px;
    margin-top: 20px;
}

.vender-order-list-main-content  .vender-order-table thead {
    background-color: #f3f4f6;
    font-weight: 600;
    color: #333;
    border-radius: 8px 8px 0 0;
}

.vender-order-list-main-content .vender-order-table th,
.vender-order-list-main-content .vender-order-table td {
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid #e6e8ec;
}

.vender-order-list-main-content .vender-order-table tbody tr:hover {
    background-color: #f9fafb;
}

.vender-order-list-main-content .vender-order-table tbody tr td:last-child {
    text-align: center;
}

.vender-order-list-main-content .vender-order-table tbody tr:nth-child(even) {
    background-color: #ffffff;
}

.vender-order-list-main-content .vender-order-table tbody tr:nth-child(odd) {
    background-color: #f9fafb;
}

.vender-order-list-main-content .vender-order-table tbody tr:hover {
    background-color: #eef2ff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.vender-order-list-main-content .vender-order-table button {
    background-color: #313131;
    color: #ffffff;
    padding: 5px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
    margin-left: 10px;
}

.vender-order-list-main-content .vender-order-table button:hover {
    background-color: #4338ca;
    box-shadow: 0 4px 12px rgba(79, 70, 229, 0.4);
    transform: translateY(-3px);
}

.vender-order-list-main-content .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.vender-order-list-main-content .page-button {
    background-color: #4f46e5;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.vender-order-list-main-content .page-button.active {
    background-color: #4338ca;
    font-weight: bold;
}

.vender-order-list-main-content .page-button:hover {
    background-color: #4338ca;
}

.vender-order-list-main-content .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 5px; /* 버튼 간격 */
}

.vender-order-list-main-content .page-button {
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}

.vender-order-list-main-content .page-button.active {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
    border-color: #007bff;
}

.vender-order-list-main-content .page-button:hover {
    background-color: #e0e0e0;
    border-color: #bbb;
}

.vender-order-list-main-content .page-button:disabled {
    background-color: #f1f1f1;
    color: #aaa;
    cursor: not-allowed;
}

.vender-order-list-main-content .pagination button {
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}

.vender-order-list-main-content .pagination button:hover:not(:disabled) {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}


/* 모바일 환경에서 테이블 스타일 변경 */
@media screen and (max-width: 768px) {
    .vender-order-table {
        display: block;
        width: 100%;
    }

    .vender-order-table thead {
        display: none; /* 헤더 숨기기 */
    }

    .vender-order-table tbody {
        display: block;
        width: 100%;
    }
    .vender-order-list-main-content .order-list-header h2 {
        font-size: 20px;
    }
    .vender-order-table tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 10px;
    }

    .vender-order-table td {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;
        font-size: 14px;
        border: none;
    }

    .vender-order-table td::before {
        content: attr(data-label); /* 데이터 라벨로 앞에 표시 */
        font-weight: bold;
        color: #333;
        flex: 1;
        text-align: left;
        margin-right: 10px;
    }

    .vender-order-table td:last-child {
        text-align: center;
        margin-top: 10px;
    }

    /* 페이지네이션 버튼 */
    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .pagination button {
        padding: 8px 12px;
        margin: 0 5px;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        cursor: pointer;
        font-size: 14px;
    }

    .pagination button.active {
        background-color: #007bff;
        color: #fff;
    }

    .pagination button:hover {
        background-color: #0056b3;
        color: #fff;
    }
}