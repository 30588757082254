/* 전체 배경 및 텍스트 색상 */
.admin-approvallist-content {
    padding: 20px;
    background-color: #f5f5f7; /* 애플 스타일의 밝은 회색 */
    color: #1d1d1f; /* 어두운 그레이 블랙 */
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.admin-approvallist-content h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #1d1d1f; /* 애플 스타일의 블랙 */
    font-weight: 600;
}

/* 필터 검색창 */
.admin-approvallist-content .filter-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.admin-approvallist-content .filter-container input {
    padding: 10px;
    border: 1px solid #d1d1d6;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    transition: box-shadow 0.3s ease;
    width: 300px;
    background-color: #ffffff;
}

.admin-approvallist-content .filter-container input:focus {
    box-shadow: 0 0 8px rgba(0, 122, 255, 0.3); /* 애플 파란색 포커스 */
    border-color: #007aff;
}

/* 승인 요청 리스트 */
.admin-approvallist-content .approval-requests {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.admin-approvallist-content .approval-items-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* 개별 요청 카드 */
.admin-approvallist-content .approval-item {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.admin-approvallist-content .approval-item:hover {
    transform: translateY(-2px); /* 살짝 떠오르는 애니메이션 */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* 강조된 그림자 */
}

/* 상태 이미지 */
.approval-image-placeholder {
    width: 80px;
    height: 80px;
    background-color: #d1d1d6; /* 기본 회색 */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
}

/* 상태에 따른 색상 */
.approval-image-placeholder.신청중 {
    background-color: #ffcc00; /* 노란색 */
}

.approval-image-placeholder.승인완료 {
    background-color: #34c759; /* 초록색 */
}

.approval-image-placeholder.승인거절 {
    background-color: #ff3b30; /* 빨간색 */
}

/* 카드 내 정보 */
.approval-info h3 {
    font-size: 16px;
    color: #1d1d1f;
    font-weight: 500;
}

.approval-info p {
    font-size: 14px;
    color: #666;
}

/* 버튼 스타일 */
.admin-approvallist-content .view-button {
    padding: 8px 14px;
    background-color: #007aff; /* 애플 파란색 */
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.3s ease;
}

.admin-approvallist-content .view-button:hover {
    background-color: #005fcb; /* 더 어두운 파란색 */
}

/* 페이징 */
.admin-approvallist-content .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.admin-approvallist-content .pagination button {
    background-color: #f5f5f7;
    border: 1px solid #d1d1d6;
    border-radius: 8px;
    padding: 8px 12px;
    margin: 0 5px;
    color: #1d1d1f;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.admin-approvallist-content .pagination button.active {
    background-color: #007aff;
    color: #fff;
    border-color: #007aff;
}

.admin-approvallist-content .pagination button:hover {
    background-color: #e5e5ea;
    border-color: #d1d1d6;
}

/* 모달 */
.admin-modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.admin-modal-content {
    background: #ffffff;
    border-radius: 16px;
    max-width: 600px;
    width: 90%;
    padding: 30px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.admin-modal-content h2 {
    font-size: 18px;
    color: #1d1d1f;
    margin-bottom: 20px;
}

.admin-modal-content p {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.admin-modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 20px;
}

.approve-button {
    background-color: #34c759;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.approve-button:hover {
    background-color: #28a745;
}

.close-modal-button {
    background-color: #007aff;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.close-modal-button:hover {
    background-color: #005fcb;
}
