/* Title and button alignment - 유지 */
.user-audition-details {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
}

.user-audition-ongoing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #333;
  margin-bottom: 20px;
}

/* 제목 스타일 - 유지 */
.user-cake-audition-ongoing-title {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

/* 작성 날짜와 버튼 컨테이너 - 유지 */
.user-audition-ongoing-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* 날짜 스타일 - 유지 */
.user-cake-audition-ongoing-write-date {
  font-size: 15px;
  color: #666;
  padding-right: 10px;
}

.user-cake-audition-ongoing-hit {
  font-size: 15px;
  color: #666;
  padding-right: 10px;
}

.user-cake-audition-ongoing-author {
  font-size: 15px;
  color: #666;
}

/* 메인 콘텐츠 영역 - 수정 */
.ongoing-main-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  background-color: #ececec;
  padding: 20px;
  border-radius: 16px;
}

/* 왼쪽 패널 - 수정 */
.ongoing-auction-info {
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 30px;
}

.ongoing-auction-info h2,
.ongoing-participating-companies h2 {
  font-size: 26px;
  color: #1a1a1a;
  font-weight: 700;
  margin-bottom: 30px;
  border-left: 4px solid #ff85a1;
  padding-left: 15px;
}

/* 케이크 정보 영역 - 유지 */
.ongoing-cake-info {
  background: #ececec;
  border-radius: 12px;
  padding: 25px;
}

.ongoing-cake-info p {
  display: flex;
  justify-content: space-between;
  font-size: 1.3em;
  color: #4a5568;
  margin-bottom: 15px;
  padding: 8px 0;
  position: relative;
  padding-left: 20px;
}

.ongoing-cake-info p::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 6px;
  height: 6px;
  background: #ff85a1;
  border-radius: 50%;
  transform: translateY(-50%);
}

.ongoing-cake-info p:last-child {
  border-bottom: none;
}

/* 예시도안 컨테이너 */
.ongoing-design-preview {
  margin-top: 40px;
  flex: 1; /* 남은 공간 차지 */
  display: flex;
  flex-direction: column;
  min-height: 0; /* flex 내에서 오버플로우 방지 */
}

.ongoing-design-preview h3 {
  font-size: 20px;
  color: #111;
  margin-bottom: 20px;
  font-weight: 600;
  flex-shrink: 0; /* 제목 크기 고정 */
}

.ongoing-design-preview img {
  width: 100%;
  height: 100%;
  min-height: 0; /* flex 내에서 오버플로우 방지 */
  object-fit: contain;
  border-radius: 12px;
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); */
}

/* 오른쪽 패널 - 수정 */
.ongoing-participating-companies {
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 30px;
}

.ongoing-company {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 20px;
  background: #ececec;
  border: none;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.ongoing-company img {
  width: 120px;
  height: 120px;
  border-radius: 12px;
  object-fit: cover;
  cursor: pointer;
}

.ongoing-company-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ongoing-company-info p {
  font-size: 1.2em;
  color: #1a1a1a;
  margin: 6px 0;
}

.ongoing-vender-name {
  font-size: 2px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
  cursor: pointer;
}

.ongoing-vender-name:hover {
  color: #3b82f6;
}

.ongoing-company-rating {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.ongoing-select-button {
  align-self: center;
  background-color: #ff85a1;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.ongoing-select-button:hover {
  background-color: #ff85a1;
  transform: scale(1.05);
}

.no-vender-list {
  font-size: 16px;
  color: #666;
  text-align: center;
  padding: 30px;
}

.user-no-cake-image {
  font-size: 16px;
  color: #666;
  text-align: center;
  padding: 30px;
}

/* 컨테이너 스타일 - 수정 */
.audition-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

/* Toggle Button Style - 수정 */
.toggles-status-container {
  text-align: right;
  right: 3px;
  transform: translateY(-30%);
  position: absolute;
}

/* 리뷰 섹션 스타일 - 수정 */
.review-section {
  margin-top: 40px;
  background-color: #ececec;
  border-radius: 16px;
  padding: 30px;
  max-height: 700px;
  overflow-y: auto;
}

.review-section::-webkit-scrollbar {
  width: 8px;
}

.review-section::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.review-section::-webkit-scrollbar-thumb {
  background: #3b82f6;
  border-radius: 4px;
}

.review-section::-webkit-scrollbar-thumb:hover {
  background: #2563eb;
}

.user-audition-details .review-section h3 {
  font-size: 24px;
  color: #1a1a1a;
  margin-bottom: 25px;
  font-weight: 700;
}

.user-audition-details .review-item {
  background-color: white;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.user-audition-details .review-image {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  object-fit: cover;
}

.user-audition-details .review-text {
  display: flex;
  flex-direction: column;
}

.user-audition-details .review-text p {
  font-size: 16px;
  color: #4a5568;
  line-height: 1.6;
}

.user-audition-details .review-text p strong {
  font-size: 18px;
  color: #333;
  display: block;
}

.user-audition-details .review-rating {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.reviews-rating {
  color: #ffd700;
  font-size: 18px;
  display: flex;
  gap: 4px;
  margin: 8px 0;
}

/* 반응형 디자인 - 추가 */
@media (max-width: 1024px) {
  .ongoing-main-content {
    grid-template-columns: 1fr;
  }
  
  .ongoing-company {
    grid-template-columns: 1fr;
    text-align: center;
  }
  
  .ongoing-company img {
    margin: 0 auto;
  }
  
  .ongoing-select-button {
    width: 100%;
    margin-top: 15px;
  }
}