/* General Styling for User Debate Section */

#user-debate-tip {
  text-align: center;
  margin-bottom: 60px;
}

#user-debate-tip h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

#user-debate-tip p {
  font-size: 28px;
}

#user-debate-tip strong {
  color: #ff85a1;
}

.user-debate-board-list {
  width: 100%;
  margin: 0 auto;
}

/* Button and Search Bar Container */
#user-debate-select-option-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  max-width: 1200px;
  margin: 0 auto 20px auto;
}

.user-debate-select-option {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-grow: 1;
  padding-left: 240px;
}

/* Updated Button Styles */
.user-debate-select-option button {
  padding: 8px 16px;
  border: 2px solid #ddd;
  border-radius: 20px;
  background-color: #f9f9f9;
  color: #4b5563;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 18px;
  font-weight: bold;
}

.user-debate-select-option button:hover {
  background-color: #ff85a1;
  color: white;
}

.user-debate-add-btn button {
  margin-bottom: 5px;
  padding: 10px 12px;
  color: #fff;
  border: 2px solid #ddd;
  border-radius: 25px;
  background-color: #ff85a1;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 18px;
}

.user-debate-add-btn button:hover {
  background-color: #ff85a1;
  color: white;
}

.pagination-page-number,
.pagination-arrow {
  background-color: #f5f5f5;
  border: none;
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 20px;
}

.pagination-page-number:hover,
.pagination-arrow:hover {
  background-color: #ddd;
}

/* Search Bar Styling */
.user-debate-search {
  position: relative;
  display: flex;
  align-items: center;
}

.user-debate-search .search-icon {
  position: absolute;
  left: 12px;
  font-size: 1rem;
  color: #999;
  pointer-events: none;
}

.user-debate-search input {
  padding: 8px 16px 8px 36px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
  font-size: 1rem;
  width: 250px;
}

.user-debate-search input:focus {
  border-color: #cbcbcb;
}

/* Pagination Styling */
.user-debate-pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
}

.pagination-page-number,
.pagination-arrow {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.3s;
  border: none;
  background-color: transparent;
}

.pagination-page-active {
  background-color: #ff85a1;
}

.pagination-arrow {
  font-size: 1.2rem;
}

.pagination-page-number:hover,
.pagination-arrow:hover {
  color: #ff85a1;
}

/* Table 전체 스타일 */
.j-discussion-list {
  width: 100%;
  max-width: 1600px; /* 최대 너비 설정 */
  margin: 20px auto; /* 수직 정렬과 중앙 정렬 */
  border-collapse: collapse; /* 셀 테두리 겹침 제거 */
  background-color: #ffffff; /* 배경색 */
  border-radius: 8px; /* 모서리를 둥글게 */
  font-size: 18px; /* 기본 글꼴 크기 */
  color: #333; /* 텍스트 색상 */
}

/* Header Row (th) 스타일 */
.j-discussion-list th {
  background-color: #e7e7e7;
  color: #333;
  font-weight: bold;
  padding: 18px 10px; /* 상하 여백을 늘림 */
  text-align: center;
  border-bottom: 2px solid #ddd;
}

/* Data Row (td) 스타일 */
.j-discussion-list td {
  padding: 18px 10px; /* 상하 여백을 늘림 */
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: #555;
}

/* Hover 효과 */
.j-discussion-list tr:hover {
  cursor: pointer; /* 마우스 포인터 변경 */
}

/* 각 열별 스타일 */
.column-id {
  width: 5%; /* 번호 열 너비 */
  font-weight: bold; /* 텍스트 굵게 */
}

.column-title {
  width: 40%; /* 제목 열 너비 */
  text-align: left; /* 텍스트 왼쪽 정렬 */
  padding-left: 15px; /* 좌측 여백 추가 */
  white-space: nowrap; /* 텍스트 한 줄 유지 */
  overflow: hidden; /* 넘치는 텍스트 숨기기 */
  text-overflow: ellipsis; /* 넘치는 텍스트는 '...'로 표시 */
}

/* 행 hover 시 title 컬러 변경 */
.j-discussion-list tbody tr:hover .column-title {
  color: #ff85a1; /* 원하는 색상으로 변경 */
  transition: color 0.3s ease; /* 부드러운 색상 전환 효과 */
}

.column-category {
  width: 15%; /* 카테고리 열 너비 */
}

.column-author {
  width: 15%; /* 작성자 열 너비 */
}

.column-date {
  width: 10%; /* 작성일 열 너비 */
  font-size: 0.9em; /* 날짜 텍스트 크기 조정 */
  color: #777; /* 날짜 텍스트 색상 */
}

.column-views {
  width: 10%; /* 조회수 열 너비 */
}

#user-debate-add {
  padding: 0px 4px;
}

.user-debate-add-btn {
  float: right;
  margin-top: 5px;
  margin-bottom: 5px;
}

.user-debate-all-count {
  float: left;
  padding: 20px 0px 0px 5px;
  top: -10px;
}

/* 페이지네이션 스타일 */
.j-pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 40px;
}

.j-page-numbers {
  padding: 8px 12px;
  border: none;
  background: white;
  font-size: 17px;
  color: #666;
  cursor: pointer;
}

.j-page-number,
.j-prev-page,
.j-next-page {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.j-page-number:hover,
.j-prev-page:hover,
.j-next-page:hover {
  background: white;
  color: #ff85a1;
  font-weight: bold;
}

.j-page-number.active {
  background: white;
  color: #ff85a1;
  border-color: #ff85a1;
  font-weight: bold;
}

.gif-container img.right-gif {
  position: absolute;
  right: 10 0px; /* 오른쪽 여백 조정 */
  top: 200px; /* 상단 여백 조정 */
  z-index: 1; /* 다른 요소 위에 겹치지 않도록 설정 */
}
.gif-container img.right-gif {
  width: 100px; /* 크기를 작게 조정 */
  height: auto;
  top: -1%;
  right: 5%;
}
