@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR&display=swap');

/* 타이틀 스타일 */
.product-registration{
    margin-top: 10px;
    border-radius: 10px;
}

.product-registration .main-content{
    max-width: 1700px;
    width: 100%;
}
.product-registration .product-list-title {
    font-size: 1.8em;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    font-family: 'IBM Plex Sans KR', sans-serif;
}
.product-registration h3 {
    font-size: 1.8em;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    font-family: 'IBM Plex Sans KR', sans-serif;
}

/* 폼 그룹 스타일 */
.product-registration .form-group {
    margin-bottom: 20px;
}

.product-registration .form-group label {
    display: block;
    font-size: 1.1em;
    margin-bottom: 10px;
    color: #333;
    font-weight: bold;
}

/* 입력 필드 공통 스타일 */
.product-registration .input-text,
.product-registration .input-select,
.product-registration .option-item input[type="checkbox"] {
    width: 100%;
    padding: 12px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 5px;
}

.product-registration .input-text:focus,
.product-registration .input-select:focus,
.product-registration .option-item input[type="checkbox"]:focus {
    border-color: #4f46e5;
    outline: none;
    box-shadow: 0 0 5px rgba(79, 70, 229, 0.3);
}

/* 이미지 업로드 스타일 */
.product-registration .image-upload-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
}

.product-registration .image-upload input[type="file"] {
    display: none;
}

.product-registration .image-placeholder {
    width: 150px;
    height: 150px;
    border: 2px dashed #ccc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    color: #aaa;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
}

.product-registration .image-placeholder:hover {
    border-color: #4f46e5;
    background-color: #f0f4ff;
}

.product-registration .image-placeholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

/* 리치 텍스트 에디터를 감싸는 컨테이너 스타일 */
.product-registration .textarea-editor {
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    margin-top: 10px;
}

.product-registration .ql-container {
    min-height: 150px;
    font-size: 1em;
}

/* 옵션 설정 섹션 */
.product-registration .options-section {
    margin: 2rem 0;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.product-registration .options-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.product-registration .add-options-button {
    padding: 0.5rem 1rem;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
}

/* 옵션 선택 컴포넌트 스타일 */
.product-registration .option-selector {
    margin-bottom: 1.5rem;
}

.product-registration .option-items {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 0.5rem;
}

.product-registration .option-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    height: 150px;
    background-color: #f9fafb;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    transition: box-shadow 0.3s, background-color 0.3s;
}

/* 체크박스를 숨기고 커스텀 스타일 적용 */
.product-registration .option-item input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* 선택된 옵션의 외곽 테두리 */
.product-registration .option-item.selected::before {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 2px solid #4f46e5;
    border-radius: 12px;
    pointer-events: none;
}

/* 선택된 옵션 배경색과 그림자 */
.product-registration .option-item.selected {
    background-color: #eef2ff;
    box-shadow: 0 4px 12px rgba(79, 70, 229, 0.15);
}

/* 서브옵션 이미지 스타일 */
.product-registration .option-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 8px;
}

/* 서브옵션 이름 스타일 */
.product-registration .option-name {
    font-size: 0.9rem;
    color: #333;
    margin-top: auto;
    text-align: center;
}

/* 버튼 스타일 */
.product-registration .add-button {
    background-color: #4f46e5;
    color: #ffffff;
    padding: 15px 20px;
    font-size: 1.1em;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 20%;
    font-weight: bold;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
}

.product-registration .add-button:hover {
    background-color: #3b37c1;
    box-shadow: 0 4px 12px rgba(59, 55, 193, 0.4);
    transform: translateY(-3px);
}

/* 떠다니는 미리보기 버튼 스타일 */
.product-registration .floating-preview-button {
    position: fixed;
    bottom: 100px;
    right: 20px;
    background-color: #4f46e5;
    color: #ffffff;
    padding: 15px 20px;
    font-size: 1.2em;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
    z-index: 1000;
}

.product-registration .floating-preview-button:hover {
    background-color: #3b37c1;
    box-shadow: 0 6px 15px rgba(59, 55, 193, 0.4);
    transform: translateY(-5px);
}

/* 모달 창 스타일 (상품 등록 폼 전용) */
.venderregistrationform-design-modal-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.venderregistrationform-design-modal {
    background: #fff;
    border-radius: 12px;
    max-width: 600px;
    width: 90%;
    padding: 30px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 1010;
    animation: fadeIn 0.3s ease-in-out;
}

/* 모달 창 애니메이션 */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* 도안 검색 입력 필드 스타일 (모달 창 전용) */
.venderregistrationform-design-modal .design-search-input {
    width: 100%;
    padding: 15px;
    margin: 20px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
}

.venderregistrationform-design-modal .design-search-input:focus {
    border-color: #4CAF50;
}

/* 도안 리스트 스타일 (모달 창 전용) */
.venderregistrationform-design-modal .design-list {
    display: flex; /* 플렉스 컨테이너 설정 */
    flex-wrap: wrap; /* 요소를 여러 줄로 배치 가능하도록 설정 */
    gap: 15px; /* 각 요소 간 간격 설정 */
    max-height: 300px; /* 최대 높이 제한 */
    overflow-y: auto; /* 내용이 넘칠 경우 스크롤 활성화 */
    justify-content: flex-start; /* 가로 정렬을 시작점 기준으로 설정 */
    align-content: flex-start; /* 세로 정렬을 시작점 기준으로 설정 */
    margin: 10px 0;

}

.venderregistrationform-design-modal .design-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    text-align: center;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.venderregistrationform-design-modal .design-item:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
}

.venderregistrationform-design-modal .design-image {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    margin-bottom: 10px;
}
.venderregistrationform-design-modal h2, h3{
    font-size: 20px;
}
.venderregistrationform-design-modal .design-name {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}

.venderregistrationform-design-modal .design-item button {
    padding: 8px 12px;
    border: none;
    background-color: #3f3f3f;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.venderregistrationform-design-modal .design-item button:hover {
    background-color: #45a049;
}

/* 닫기 버튼 스타일 (모달 창 전용) */
.venderregistrationform-design-modal .close-modal-button {
    background-color: #ff5e57;
    color: #fff;
    padding: 5px 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    align-items: center;
}

.venderregistrationform-design-modal .close-modal-button:hover {
    background-color: #e0473d;
}

.venderregistrationform-design-modal .close-modal-button::before {
    margin-right: 5px;
    font-size: 14px;
}



.product-registration .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.product-registration .page-button {
    margin: 0 5px;
    padding: 8px 16px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.product-registration .page-button:hover {
    background-color: #ddd;
}

.product-registration .page-button.active {
    background-color: #007bff;
    color: white;
}


.product-registration .centered-button-group {
    text-align: end;
    /* 컨테이너가 가로 전체를 차지하도록 */
    margin-top: 20px;
    margin-left: 10px;
}
.product-registration .centered-button-group button{
    margin-left: 20px;
}

/* 케이크 도안 선택 섹션 스타일 (상품 등록 폼 전용) */
.vender-container .product-registration .cakeDesign-section {
    margin-top: 30px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fafafa;
    margin-bottom: 20px;
}

.vender-container .product-registration .options-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.vender-container .product-registration .options-header h2 {
    font-size: 18px;
    color: #333;
}

.vender-container .product-registration .selected-design {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.vender-container .product-registration .selected-design img {
    width: 60px;
    height: auto;
    border-radius: 4px;
}

.vender-container .product-registration .selected-design span {
    font-size: 14px;
    color: #444;
}

/* 라디오 버튼 숨기기 및 커스텀 스타일 적용 */
.product-registration .option-item input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* 라디오 버튼 커스텀 스타일 */
.product-registration .option-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    height: 150px;
    background-color: #f9fafb;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    transition: box-shadow 0.3s, background-color 0.3s;
}

/* 선택된 라디오 버튼 스타일 */
.product-registration .option-item.selected {
    background-color: #eef2ff;
    box-shadow: 0 4px 12px rgba(79, 70, 229, 0.15);
    border: 2px solid #4f46e5;
}

/* 서브옵션 이미지 스타일 */
.product-registration .option-item img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 8px;
}

/* 서브옵션 이름 스타일 */
.product-registration .option-item span {
    font-size: 0.9rem;
    color: #333;
    text-align: center;
}

.venderregistrationform-design-modal {
    width: 80%;
    max-width: 1000px;
    margin: auto;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 90vh;
}

.venderregistrationform-design-modal-overlay {
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-registration .design-search-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.product-registration .design-list {
    margin-bottom: 30px;
}

.product-registration .design-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    margin: 10px;
    text-align: center;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 10px;
    background: #f9f9f9;
    transition: box-shadow 0.3s;
}

.product-registration .design-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-registration .design-item img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 5px;
}

.product-registration .design-item .design-name {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-registration .design-item button {
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.product-registration .design-item button:hover {
    background-color: #45a049;
}

.product-registration .close-modal-button {
    background: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    float: right;
}

.selected-design {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.selected-design-image {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}

.no-image-placeholder {
    font-size: 14px;
    color: gray;
}
