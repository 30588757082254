/* 전체 레이아웃 설정 */
.vender-signup {
  width: 450px;
  margin: 0px auto 150px auto;
  text-align: left;
}

.vender-signup img {
  margin-right: 40px;
  width: 250px;
}

.vender-signup img {
  display: block;
  margin: 0 auto 10px auto;
}

.vender-signup h1 {
  font-size: 35px;
  font-weight: bold;
  color: #333;
  text-align: center;
  padding-top: 4px;
}

.vender-signup h2 {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin: 50px 0px 30px 0px;
  position: relative;
}

.vender-signup h2::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #333;
  position: absolute;
  bottom: -10px;
  left: 0;
}

/* 입력 필드 스타일 */
.vender-signup-area {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.vender-input-group {
  margin-bottom: 20px; /* label과 input 간격을 위한 스타일 */
}

.vender-id-ok,
.vender-pw-ok {
  padding-top: 6px;
  font-size: 15px;
  /* font-weight: bold; */
  color: red;
}

.vender-signup-area label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
  display: block;
}

.vender-signup-area input[type="text"],
.vender-signup-area input[type="password"] {
  width: 100%;
  padding: 15px 12px;
  font-size: 14px;
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
}

/* Placeholder 스타일 */
.vender-signup-area input::placeholder {
  color: #aaa;
}

/* 포커스 스타일 */
.vender-signup-area input:focus {
  outline: none;
  border-color: #bbbbbb;
}

/* 회원가입 버튼 스타일 */
.vender-signup-btn {
  text-align: center;
  margin-top: 40px;
}

.vender-signup-btn button {
  width: 50%;
  padding: 16px 0;
  font-size: 16px;
  color: #fff;
  background-color: #ff85a1;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

.vender-signup-area input[type="file"] {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  color: #333;
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
}

/* 파일 선택 버튼 스타일 */
.vender-signup-area input[type="file"]::file-selector-button {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
