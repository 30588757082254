/* 페이지 스타일 */
.admin-venderorder-page {
    flex: 1; /* 사이드바를 제외한 나머지 공간 채우기 */
    padding: 20px;
    margin: 0; /* 불필요한 마진 제거 */
    background-color: #ffffff;
    overflow: auto; /* 내부 콘텐츠 스크롤 가능 */
    box-sizing: border-box; /* 패딩 포함한 사이즈 계산 */
    max-width: calc(100% - 240px); /* 사이드바 크기를 제외한 너비 */
}

/* 헤더 스타일 */
.admin-venderorder-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.admin-venderorder-header h1 {
    font-size: 1.8em;
    color: #1e3a8a;
    font-weight: bold;
}

/* 필터 컨테이너 스타일 */
.admin-venderorder-page .filter-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.admin-venderorder-page .filter-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1;
    min-width: 150px;
}

.admin-venderorder-page .filter-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-venderorder-page .filter-button:hover {
    background-color: #0056b3;
}

/* 테이블 스타일 */
/* 테이블 스타일 */
.admin-venderorder-page .order-table {
    width: 100%;
    table-layout: fixed; /* 열의 너비를 균등하게 유지 */
    border-collapse: collapse; /* 테두리 병합 */
    margin-top: 20px;
    background-color: white;
    border-radius: 8px; /* 테이블 모서리 둥글게 */
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.admin-venderorder-page .order-table th,
.admin-venderorder-page .order-table td {
    border: 1px solid #ddd; /* 셀 테두리 */
    padding: 12px;
    text-align: center; /* 수평 가운데 정렬 */
    vertical-align: middle; /* 수직 가운데 정렬 */
    word-wrap: break-word; /* 긴 텍스트 줄바꿈 */
    font-size: 14px;
    height: 60px; /* 셀 높이 일정 */
}

.admin-venderorder-page .order-table th {
    background-color: #1e40af; /* 헤더 배경색 */
    color: white;
    font-weight: bold;
}

.admin-venderorder-page .order-table td {
    background-color: #ffffff;
}

/* 짝수 행 배경색 */
.admin-venderorder-page .order-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* 마우스 오버 시 배경색 변경 */
.admin-venderorder-page .order-table tr:hover {
    background-color: #f1f1f1;
}


.admin-venderorder-page .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    gap: 8px;
}

.admin-venderorder-page .pagination button {
    padding: 8px 12px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.admin-venderorder-page .pagination button:hover {
    background-color: #ddd;
}

.admin-venderorder-page .pagination button.active {
    background-color: #1e40af;
    color: white;
    font-weight: bold;
    cursor: default;
}
