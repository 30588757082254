/* 경매 아이콘 위치 */
.user-cake-audition-status {
  position: absolute;
  top: 8px;
  right: 8px;
  background: white;
  border-radius: 25px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2; /* 다른 요소보다 위에 오도록 설정 */
}

/* 진행중 상태 스타일 */
.status-in-progress {
  background-color: #0056b3;
  color: white;
}

/* 종료 상태 스타일 */
.status-completed {
  background-color: #EA4335;
  color: white;
}

img[src="/images/logo2-no.png"] {
  width: 150px;
  height: 150px;
  object-fit: contain;
  display: block;
  margin: 100px auto;
}