.user-cake-audition-board-list {
  width: 100%;
  margin: 0 auto;
}

.user-cake-audition-main {
  max-width: 1000px;
  border-radius: 20px;
  padding: 40px 50px;
  margin: 0 auto;
  border: 1px solid #dddddd;
  transition: transform 0.3s;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.03);
}

/* 타이틀 스타일 */
.user-cake-audition-title {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  display: inline-block;
  position: relative;
  padding-bottom: 15px;
}

.user-cake-audition-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #ff85a1;
  border-radius: 2px;
}

.user-cake-audition-title-container {
  text-align: center;
  /* margin-bottom: 30px; */
}


.user-cake-audition-form-group {
  margin-bottom: 28px;
}

.audition-info-notice1 {
  color: #ff587f;
  padding: 50px 0px 15px 0px;
}

.audition-info-notice2 {
  color: #666666;
  padding-bottom: 15px;
}

.user-cake-audition-form-group span {
  color: #ff85a1;
}

.user-cake-audition-form-group label {
  font-size: 1.1em;
  color: #333;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
}

.user-file-input-wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0;
  background-color: #f1f1f1;
  padding: 3px 15px;
  border: 1px solid #ddd;
  border-radius: 12px;
  transition: all 0.2s ease;
}

.user-file-input-wrapper input[type="file"] {
  flex: 1;
  padding: 10px;
  font-size: 0.95em;
  border: none;
  background: transparent;
  color: #666;
}

.user-cake-audition-remove-button {
  margin-left: 10px;
  background-color: transparent;
  color: #666;
  border: none;
  cursor: pointer;
  font-size: 1.4em;
  border-radius: 8px;
  transition: all 0.2s ease;
  padding: 8px;
}

.user-cake-audition-remove-button:hover {
  color: #ff85a1;
}

.user-cake-audition-add-image-button {
  background-color: #fff;
  color: #666;
  border: 1px solid #ddd;
  cursor: pointer;
  font-size: 0.95em;
  padding: 8px 20px;
  border-radius: 20px;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.user-cake-audition-add-image-button:hover {
  background-color: #ff85a1;
  color: white;
  border-color: #ff85a1;
}

.user-audition-input-text {
  width: 100%;
  padding: 16px;
  font-size: 1em;
  border: 1px solid #ddd;
  background-color: #f1f1f1;
  color: #444;
  border-radius: 12px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.user-audition-input-text:focus {
  outline: none;
  border-color: #ff85a1;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(255, 133, 161, 0.1);
}

.user-audition-input-text::placeholder {
  color: #999;
}

.user-audition-add {
  text-align: center;
}

.user-cake-audition-add-button {
  width: 25%;
  padding: 15px 0;
  background-color: #ff85a1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1em;
  font-weight: 500;
  display: inline-block;
  margin: 40px 10px;
  cursor: pointer;
}

.user-cake-audition-cancel-button {
  width: 25%;
  padding: 15px 0;
  background-color: #ffffff;
  color: #666666;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  font-size: 1em;
  font-weight: 500;
  display: inline-block;
  margin: 40px 10px;
  cursor: pointer;
}

/* 탭 메뉴 스타일 */
.user-audition-tabs {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
}

.user-audition-tabs button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 25px;
  background-color: #f1f1f1;
  color: #666;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.user-audition-tabs button.active,
.user-audition-tabs button:hover {
  background-color: #333;
  color: #fff;
  border-color: #333;
}

.user-audition-design-preview p {
  color: #999;
  font-size: 0.95em;
}

/* 탭 내용 영역 */
.user-audition-design-preview {
  padding: 20px;
  border: 2px dashed #cacaca;
  border-radius: 5px;
  background-color: #f1f1f1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; /* 아이템을 위쪽 정렬 */
  min-height: 300px;
}

.user-audition-liked-designs {
  display: flex;
  gap: 15px;
  flex-wrap: wrap; /* 줄바꿈 허용 */
  justify-content: center; /* 아이템 중앙 정렬 */
  width: 100%; /* 부모 컨테이너의 너비를 따름 */
  max-width: 900px; /* 최대 너비 제한 */
  margin: 0 auto; /* 중앙 정렬 */
  overflow-y: auto; /* 세로 스크롤 허용 */
}


/* 기본 상태에서 스크롤바 숨김 */
.user-audition-liked-designs::-webkit-scrollbar {
  width: 0;
}

/* 마우스를 올렸을 때 스크롤바 표시 */
.user-audition-liked-designs:hover::-webkit-scrollbar {
  width: 8px;
  background-color: #e0e0e0;
}

/* 스크롤바 슬라이더 */
.user-audition-liked-designs::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 4px;
}

.user-audition-liked-designs::-webkit-scrollbar-thumb:hover {
  background-color: #9e9e9e;
}

.user-audition-liked-design-card {
  width: 140px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s;
  cursor: pointer;
}

.user-audition-liked-design-card img {
  max-width: 118px;
  height: 118px;
  border-radius: 4px;
  margin-bottom: 10px;
  object-fit: cover;
}

/* 선택된 카드에 효과 추가 */
.user-audition-liked-design-card.selected {
  border: 2px solid #ff85a1;
  transform: scale(1.03);
}

/* 사진 업로드 섹션 */
.user-audition-upload-section {
  padding: 0px 20px 20px 20px;
  border: none;
  border-radius: 10px;
  background-color: #f1f1f1;
  text-align: center;
  transition: border-color 0.3s;
}

.user-audition-upload-section input[type="file"] {
  display: inline-block;
  margin-top: 10px;
  padding: 12px 20px 12px 130px;
  font-size: 1em;
  border: noned;
  border-radius: 6px;
  background-color: #f1f1f1;
  color: #555;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
}

.user-audition-uploaded-image-preview {
  margin-top: 15px;
}

.user-audition-uploaded-image-preview img {
  max-width: 200px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* DatePicker input 필드 스타일 */
.date-picker-input {
  width: 100%;
  padding: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  caret-color: transparent;
}

.date-picker-input:focus {
  outline: none;
  border-color: #ddd;
}

/* 가격 범위 버튼 스타일 */
.price-range-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 15px;
}

.price-range-buttons button {
  padding: 6px 10px;
  border: 1px solid #e0e0e0;
  background-color: white;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
  color: #333;
}

.price-range-buttons button.active {
  background-color: #ff6b6b;
  color: white;
  border-color: #ff6b6b;
}

/* 슬라이더 컨테이너 */
.price-slider-container {
  margin-top: 20px;
  width: 100%;
  padding: 0 10px;
}

.price-slider-labels {
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
  margin-bottom: 10px;
}

/* 커스텀 범위 슬라이더 */
.custom-range-slider {
  position: relative;
  height: 30px;
  margin: 20px 0;
}

/* 슬라이더 트랙 */
.slider-track {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 4px;
  background: #e0e0e0;
  border-radius: 2px;
}

/* 활성화된 슬라이더 트랙 */
.slider-track-active {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  background-color: #ff6b6b;
  z-index: 1;
}

/* 슬라이더 핸들 */
.slider-handle {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  border: 2px solid #ff6b6b;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  cursor: pointer;
  z-index: 2;
  touch-action: none;
}

.min-handle {
  z-index: 3; /* 최소값 핸들이 항상 위에 오도록 */
}

.price-display {
  text-align: center;
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}