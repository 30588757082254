@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR&display=swap');

#user-wrap.text-center {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#user-wrap.text-center #d-user-wrap-body {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
}

#user-wrap.text-center #d-user-wrap-body .payment-container {
    width: 100%;
    max-width: 1065px;
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #d1d1d1;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    font-family: 'IBM Plex Sans KR', sans-serif;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .payment-content {
    padding: 30px;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .product-info {
    display: flex;
    gap: 40px;
    margin-bottom: 30px;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .product-info .product-image {
    flex: 0 0 420px;
    overflow: hidden;
    height: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .product-info .product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .product-info .product-details {
    flex: 1;
    text-align: left;
    margin-left: 20px;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .payment-label {
    font-size: 1.3em;
    color: #666;
    margin-bottom: 5px;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .product-title {
    font-size: 2.0em;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 2px solid #dddddd;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .detail-item {
    margin-bottom: 15px;
    line-height: 1.6;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .detail-item .label {
    font-size: 1.1em;
    font-weight: bold;
    color: #555;
    min-width: 132px;
    display: inline-block;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .detail-item .value {
    font-size: 1.1em;
    color: #333;
    margin-left: 20px;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .detail-item .price {
    font-size: 1.2em;
    color: #333;
    font-weight: bold;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .detail-item.message {
    margin-top: 20px;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .detail-item.note {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 5px;
    border: 2px solid #ddd;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .payment-button {
    display: block;
    width: 200px;
    margin: 30px auto 0;
    padding: 15px 20px;
    background-color: #848484;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 1.1em;
    cursor: pointer;
    transition: all 0.3s ease;
}

#user-wrap.text-center #d-user-wrap-body .payment-container .payment-button:hover {
    background-color: #333;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
    #user-wrap.text-center #d-user-wrap-body .payment-container .product-info {
        flex-direction: column;
        align-items: center;
    }

    #user-wrap.text-center #d-user-wrap-body .payment-container .product-info .product-image {
        flex: none;
        width: 300px;
        height: 300px;
    }

    #user-wrap.text-center #d-user-wrap-body .payment-container .product-info .product-details {
        padding: 20px 0;
        width: 100%;
    }

    #user-wrap.text-center #d-user-wrap-body .payment-container .product-title {
        font-size: 1.5em;
    }

    #user-wrap.text-center #d-user-wrap-body .payment-container .detail-item .label {
        min-width: 120px;
    }
}

/* 인쇄 스타일 */
@media print {
    #user-wrap.text-center #d-user-wrap-body .payment-container {
        box-shadow: none;
    }

    #user-wrap.text-center #d-user-wrap-body .payment-container .payment-button {
        display: none;
    }
}