.user-cake-design-board-list {
  width: 100%;
  margin: 0 auto; /* 가로 가운데 정렬 */
}

/* 디자인 소개 섹션 */
#user-cake-design-tip {
  animation: slideIn 0.8s ease-out;
  text-align: center;
  margin-bottom: 70px;
}

@keyframes slideIn {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

#user-cake-design-tip h2 {
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 20px;
}

#user-cake-design-tip p {
  font-size: 25px;
}

#user-cake-design-tip strong {
  color: #ff85a1;
}

/* 상단 옵션 및 검색 */
#user-cake-design-select-option-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.user-cake-design-select-option {
  display: flex;
  gap: 20px;
  align-items: center; /* 수평 정렬 */
}

.user-cake-design-select-option button {
  padding: 10px 20px;
  border: 1px solid #ff85a1;
  border-radius: 25px;
  background-color: white;
  color: #ff85a1;
  cursor: pointer;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}

.user-cake-design-select-option button:hover {
  background-color: #ff85a1;
  color: white;
}

/* 선택된 옵션 버튼 스타일 */
.user-cake-design-select-option button.active-option {
  background-color: #ff85a1; /* 배경색 */
  color: white; /* 텍스트 색상 */
  border: 1px solid #ff85a1; /* 경계선 색상 */
}

.user-cake-design-search {
  position: relative;
  display: flex;
  align-items: center; /* 수평 정렬 */
}

.user-cake-design-search .search-icon {
  position: absolute;
  right: 15px;
  bottom: 11px;
  color: #999999;
  cursor: pointer;
}

.user-cake-design-search input {
  width: 300px;
  padding: 10px 20px; /* 아이콘과의 간격 */
  border: 1px solid #999999;
  border-radius: 25px;
  font-size: 17px;
}

.user-cake-design-search input:focus {
  outline: none;
}

/* 도안 등록 버튼 및 카운트 */
#user-cake-design-add {
  display: flex;
  align-items: center;
  justify-content: space-between; /* ALL과 버튼을 양쪽으로 배치 */
  margin-bottom: 15px;
}

.user-cake-design-all {
  font-size: 17px;
  font-weight: bold;
  padding-left: 3px;
  transform: translateY(10px); /* ALL 텍스트를 살짝 아래로 이동 */
}

.user-cake-design-add-btn {
  margin-left: auto; /* 오른쪽으로 밀어내기 */
}

.user-cake-design-add-btn button {
  padding: 10px 20px;
  border-radius: 25px;
  background-color: #ff85a1;
  border: 1px solid #ff85a1;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* .user-cake-design-add-btn button:hover {
  background-color: #ff4b87;
} */

.user-cake-design-list-container {
  width: 100%;
}

/* 리스트 그리드 */
.user-cake-design-list-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.user-cake-design-empty-grid {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-cake-design-empty-grid p {
  font-size: 18px;
  color: #666;
}

/* 카드 스타일 */
.user-cake-design-card {
  background: none;
  border: none;
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative; /* 부모 요소로 사용 */
  margin-bottom: 40px;
}

.user-cake-design-card-image {
  width: 100%;
  height: 350px;
  border-radius: 25px;
  overflow: hidden; /* 이미지가 컨테이너를 벗어나지 않도록 설정 */
}

/* 카드 이미지 스타일 */
.user-cake-design-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out; /* 확대 효과의 부드러움 */
}

.user-cake-design-card-image img:hover {
  transform: scale(1.1);
}

/* 좋아요 아이콘 위치 수정 */
.user-cake-design-card-likes {
  position: absolute;
  top: 8px;
  right: 8px;
  background: white;
  border-radius: 25px;
  padding: 6px 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2; /* 다른 요소보다 위에 오도록 설정 */
}

.heart-icon {
  color: #ff85a1;
  margin-right: 5px;
}

/* 카드 정보 스타일 */
.user-cake-design-card-info {
  padding: 10px;
}

.user-cake-design-card-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vendor-badge {
  background-color: #d3d3d3;
  color: #333;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 12px;
  margin-left: 5px;
}

.user-cake-design-card-subtitle {
  font-size: 17px;
  color: #999;
  margin-bottom: 10px;
}

.user-cake-design-card-status {
  font-size: 15px;
  color: #666;
}

/* 페이지네이션 */
.user-cake-design-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}

.user-cake-design-page-number,
.user-cake-design-prev-page,
.user-cake-design-next-page {
  padding: 8px 12px;
  border: none;
  background: white;
  font-size: 17px;
  color: #666;
  cursor: pointer;
}

.user-cake-design-page-number.active {
  color: #ff85a1;
  font-weight: bold;
}

.user-cake-design-page-number:hover,
.user-cake-design-prev-page:hover,
.user-cake-design-next-page:hover {
  font-weight: bold;
  color: #ff85a1;
}
