/* Form Styling */
.user-edit-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}

.user-edit-form label {
  font-size: 1.0rem;
  font-weight: bold;
}

.user-edit-form input[type="text"],
.user-edit-form input[type="password"],
.user-edit-form input[type="tel"] {
  padding: 12px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  transition: all 0.3s ease;
}

.user-edit-form input:focus {
  outline: none;
  border-color: #ff85a1;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(255, 133, 161, 0.1);
}

/* Profile Picture Section */
.profile-picture-section {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.profile-picture-preview {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddddd;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
}

.profile-picture-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.profile-picture-input {
  display: none;
}

/* 프로필 사진 버튼 수정 */
.profile-picture-button {
  padding: 13px 15px;
  font-size: 0.9rem;
  color: #666;
  background-color: #ececec;
  border: 1px solid #dddddd;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  transition: all 0.3s ease;
}

/* .profile-picture-button:hover {
    background-color: #ebebeb;
} */

.j-add-event-section {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-bottom: 15px;
}

.j-add-event-section input[type="text"],
.j-add-event-section input[type="date"] {
  flex: 1;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #dddddd;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.j-add-event-section input:focus {
  border-color: #ff85a1;
  box-shadow: 0 0 0 2px #ffe9ee;
  outline: none;
}

/* 기념일 추가 버튼 수정 */
.j-add-event-section button {
  padding: 0px 15px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #ff85a1;
  color: white;
  border: 1px solid #ff85a1;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
  flex-shrink: 0;
  transition: all 0.3s ease;
}

.j-event-item {
  display: grid;
  grid-template-columns: 1fr auto; /* 2개의 컬럼으로 변경 */
  align-items: center;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

.j-event-item:hover {
  background-color: #ffe9ee;
}

.j-event-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.j-event-name {
  font-weight: bold;
  font-size: 1.1rem;
  color: #ff85a1;
}

.j-event-date {
  color: #666;
  font-size: 0.95rem;
}

.j-event-delete {
  padding: 6px 41px; /* 패딩 줄임 */
  width: auto; /* width: 100% 제거 */
  font-size: 0.9rem;
  font-weight: bold;
  background-color: white;
  color: #666;
  border: 1px solid #ff85a1;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 15px; /* 왼쪽 여백 추가 */
}

/* .j-event-delete:hover {
    background-color: #ebebeb;
} */

.user-edit-buttons {
  display: flex;
  gap: 15px;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
}

.user-cancel-button,
.user-save-button {
  padding: 13px 30px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  flex: 1;
  max-width: 200px;
  transition: all 0.3s ease;
}

/* 취소/저장 버튼 수정 */
.user-cancel-button {
  background-color: white;
  border: 1px solid #dddddd;
  color: #666;
}

.user-save-button {
  background-color: #ff85a1;
  border: 1px solid #ff85a1;
  color: white;
}

.j-user-withdrawal-section {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.j-user-withdrawal-button {
  font-size: 0.95rem;
  background-color: white;
  color: #555;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: underline;
}

.user-edit-txt {
  color: #374151;
}

input {
  color: #555;
  font-size: 14px;
}

input::placeholder {
  color: #bbbbbb;
  opacity: 1;
}
