.user-cake-design-board-list {
  width: 100%;
  margin: 0 auto;
}

.user-cake-design-main {
  max-width: 1000px;
  border-radius: 20px;
  padding: 40px 50px;
  margin: 0 auto;
  border: 1px solid #dddddd;
  transition: transform 0.3s;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.03);
}

/* 타이틀 스타일 */
.user-cake-design-title {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  display: inline-block;
  position: relative;
  padding-bottom: 15px;
}

.user-cake-design-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #ff85a1;
  border-radius: 2px;
}

.user-cake-design-title-container {
  text-align: center;
  margin-bottom: 30px;
}

/* 이미지 미리보기 스타일 */
.user-cake-design-imgs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  min-height: 200px;
  padding: 30px;
  background-color: #f1f1f1;
  border: 2px dashed #cacaca;
  border-radius: 12px;
  margin: 15px 0 30px 0;
  align-items: center;
  justify-content: center;
}

.user-cake-design-imgs:empty {
  position: relative;
}

.user-cake-design-imgs:empty::after {
  content: '이미지를 추가해주세요.';
  position: absolute;
  color: #999;
  font-size: 0.95em;
}

.user-cake-design-imgs img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease;
}

/* 폼 그룹 스타일 */
.user-cake-design-form-groups {
  margin-bottom: 28px;
}

.user-cake-design-form-groups label {
  font-size: 1.1em;
  color: #333;
  font-weight: 600;
  margin-bottom: 16px;
  display: block;
}

.form-group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

/* .user-cake-design-form-groups input[type="text"],
.user-cake-design-form-groups textarea {
  margin-top: 8px;
} */

/* 파일 입력 필드 스타일 */
.user-file-input-wrappers {
  display: flex;
  align-items: center;
  margin: 10px 0;
  background-color: #f1f1f1;
  padding: 3px 15px;
  border: 1px solid #ddd;
  border-radius: 12px;
  transition: all 0.2s ease;
}

.user-file-input-wrappers input[type="file"] {
  flex: 1;
  padding: 10px;
  font-size: 0.95em;
  border: none;
  background: transparent;
  color: #666;
}

.user-remove-button {
  margin-left: 10px;
  background-color: transparent;
  color: #666;
  border: none;
  cursor: pointer;
  font-size: 1.4em;
  border-radius: 8px;
  transition: all 0.2s ease;
  padding: 8px;
}

.user-remove-button:hover {
  color: #ff85a1;
}

/* 추가 버튼 스타일 수정 */
.user-add-image-button {
  background-color: #fff;
  color: #666;
  border: 1px solid #ddd;
  cursor: pointer;
  font-size: 0.95em;
  padding: 8px 20px;
  border-radius: 20px;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.user-add-image-button:hover {
  background-color: #ff85a1;
  color: white;
  border-color: #ff85a1;
}

/* 입력 필드 공통 스타일 */
.user-input-text {
  width: 100%;
  padding: 16px;
  font-size: 1em;
  color: #444;
  border: 1px solid #dddddd;
  background-color: #f1f1f1;
  border-radius: 12px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.user-input-text:focus {
  outline: none;
  border-color: #ff85a1;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(255, 133, 161, 0.1);
}

.user-input-text::placeholder {
  color: #999;
}

.user-ckae-design-btn-group {
  text-align: center;
}

.user-cake-design-add-button {
  width: 25%;
  padding: 15px 0;
  background-color: #ff85a1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1em;
  font-weight: 500;
  display: inline-block;
  margin: 40px 10px;
  cursor: pointer;
}

.user-cake-design-cancel-button {
  width: 25%;
  padding: 15px 0;
  background-color: #ffffff;
  color: #666666;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  font-size: 1em;
  font-weight: 500;
  display: inline-block;
  margin: 40px 10px;
  cursor: pointer;
}

.user-cake-design-input-text {
  width: 100%;
  padding: 16px;
  font-size: 1em;
  border: 1px solid #ddd;
  background-color: #f1f1f1;
  color: #444;
  border-radius: 12px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.user-cake-design-input-text:focus {
  outline: none;
  border-color: #ff85a1;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(255, 133, 161, 0.1);
}

.user-cake-design-input-text::placeholder {
  color: #999;
}

/* 해시태그 */
.hashtag-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 4px;
  background-color: white;
  min-height: 40px;
  cursor: pointer;
}

.hashtag-badge {
  display: inline-flex;
  align-items: center;
  background-color: #f0f7ff;
  color: #0078ff;
  border-radius: 16px;
  padding: 5px 10px;
  font-size: 1em;
}

.hashtag-remove {
  background: none;
  border: none;
  color: #888;
  margin-left: 4px;
  cursor: pointer;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.hashtag-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 4px 0;
  min-width: 120px;
  font-size: 1em;
  background: transparent;
}

.hashtag-input::placeholder {
  color: #919191;
}