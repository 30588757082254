@charset "UTF-8";

#user-wrap {
  font-weight: 400;
  font-style: normal;
}

.userMainFormContainer button {
  cursor: pointer;
  border-radius: 5px;
}
/*검색*/
.main-select-wrap{
  background-color: #333;
  height: 20vh;
  display: flex;
  flex-direction: column; /*세로정렬*/
  justify-content: center;  /* 중앙 정렬 */
}
.main-select-flexbox{
  background-color: #ffffff;
  flex-grow: 1;   /*공간을 공평하게 차지하도록 설정 */
  width: 100%;
  height: 33%;
  text-align: center;
  position: relative; /* 부모 요소에 relative positioning 설정 */
}
.main-select-flexbox h2{

  font-size: 28px;
  position: absolute;
  bottom: 0; /* 하단에 고정 */
  margin-bottom: 20px;
  left: 50%; /* 수평 중앙 정렬 */
  transform: translateX(-50%); /* 정확한 중앙 정렬을 위해 */
}

 /*메인 카테고리 리스트 탭*/

.main-category-box{
  width: 45%;
  height: 55%;
  margin: auto;
  position: relative;  /* 부모 요소에 상대적인 위치 설정 */
  margin-top: 20px;
}

.main-category-list {
  height: 100%;
  display: flex;
  flex-direction: row;
  max-width: 100%;  /* 최대 너비 제한 */
  position: relative;  /* 추가된 부분: 화살표를 li 끝에 맞추기 위함 */
  border-radius: 10px;
  background-color: #FFD1DC; /* 투명도 50%로 설정 */
  background-color: #FF9CA7; /* 배너 그라데이션의 끝 색 */
  color: white;
 }
.main-category-list li{
  width: 25%;  /* 각 li가 가로 공간을 공평하게 차지하도록 설정 */
  flex-shrink: 0; /* li가 축소되지 않도록 설정 */
  font-size: 20px;
  border-radius: 8px;
  /* border: solid 1px #fff; */
 }

 /* .main-category-list li:first-child{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid #fff;
 }
 .main-category-list li:last-child{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 1px solid #fff;
 } */
 .main-category-list li>button{
  all: unset; /* 모든 기본 스타일을 제거 */
  background: none; /* 배경 없애기 */
  border: none; /* 테두리 없애기 */
  padding: 0; /* 패딩 없애기 */
  font: inherit; /* 폰트 스타일 부모로부터 상속받기 */
  cursor: pointer; /* 마우스 커서가 포인터로 변경되도록 */
  width: 100%;
  height: 100%;
}
/* 첫 번째 버튼 모서리 둥글게 */
.main-category-list li:first-child > button {
  border-top-left-radius: 15px;  /* 왼쪽 상단 둥글게 */
  border-bottom-left-radius: 15px;  /* 왼쪽 하단 둥글게 */
}

/* 마지막 버튼 모서리 둥글게 */
.main-category-list li:last-child > button {
  border-top-right-radius: 15px;  /* 오른쪽 상단 둥글게 */
  border-bottom-right-radius: 15px;  /* 오른쪽 하단 둥글게 */
}

/*카테고리 화살표*/
#scroll-right {
  position: absolute;
  top: 50%;
  right: -25px;  /* li의 끝에 위치하도록 설정 */
  transform: translateY(-50%);

  color: #ff6f61;
  font-size: 30px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10; /* 화살표가 리스트 위에 표시되도록 설정 */
}

#scroll-right:hover {
  /* 마우스를 올렸을 때 배경색 변경 */
}

#scroll-left {
  position: absolute;
  top: 50%;
  left: -25px;  /* li의 끝에 위치하도록 설정 */
  transform: translateY(-50%);

  color: #ff6f61;
  font-size: 30px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10; /* 화살표가 리스트 위에 표시되도록 설정 */
}
 /*검색*/
 .main-area-search-box {
  display: flex; /* 자식 요소들을 가로로 정렬 */
  flex-direction: row; /*세로정렬*/
  position: relative; /* 상대적으로 위치 설정 */
  width: 45%;
  height: 100%;
  margin: 0 auto;
}

#main-area-search-input {
  width: 80%; /* input의 너비 */
  height: 55%; /* 높이는 부모 요소의 높이에 맞추기 */
  padding: 7px 10px;
  font-size: 20px;
  border-radius: 10px 0 0 10px; /* 왼쪽 모서리 둥글게 */
  box-sizing: border-box; /* padding을 width에 포함시킴 */
  border: 2px solid #ccc; /* 회색 선 */
}

#main-area-search-input:focus {
  /*outline: none;  !* 포커스 시 기본 선 제거 *!*/
  /*border: none;   !* 포커스 시 테두리 선 제거 (선택 사항) *!*/
}

#main-area-search-button {
  width: 20%; /* 버튼 너비 */
  height: 55%; /* 버튼 높이 */
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 0 10px 10px 0; /* 오른쪽 모서리 둥글게 */
  cursor: pointer;
  font-size: 16px;
}


.userMainFormContainer .allList-box {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 60px;
  margin-top: 15px;
}

.userMainFormContainer .all-product-item {
  width: calc((100% - 80px) / 4);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.userMainFormContainer .list_hover_img {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userMainFormContainer .list_hover_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.userMainFormContainer .allList-item {
  padding: 10px;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: color 0.3s ease;
}

/* 매장 정보 (로고와 상호명)를 이미지 상단에 겹쳐서 표시 */
.userMainFormContainer .store-info {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

.userMainFormContainer .store-info img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}

.userMainFormContainer .product-info {
  width: 100%;
  padding: 10px;
  text-align: left;
  background-color: #f8f8f8;
}

.userMainFormContainer .product-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

/* 내부 이미지 배치 */
.userMainFormContainer .userMain-product-img img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

/* 텍스트 스타일 */
.userMainFormContainer .allList-item b {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 10px 0 5px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.userMainFormContainer .allList-item b:hover {
  color: #ff6b6b;
  /* 포인트 색상 */
}

.userMainFormContainer .allList-item p {
  font-size: 14px;
  color: #666;
  cursor: pointer;
  transition: color 0.3s ease;
}

.userMainFormContainer .allList-item p:hover {
  color: #333;
}

.userMainFormContainer .banner {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  height: 400px;
  background: linear-gradient(
          to right,
          rgba(255, 105, 135, 0.8),
          rgba(255, 156, 167, 0.8)
  );
  /*url("../../../images/banner-image.jpg");*/
  background-size: 300% 100%, cover;
  background-position: 0% 50%, center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  animation: slideBackground 15s linear infinite;
  overflow: hidden;
}


.category-map-container {
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}



.userMainFormContainer .banner h1 {
  font-size: 50px;
  /* 원하는 제목 크기 */
  font-weight: bold;
  color: white;
  letter-spacing: 2px;
  /* 글자 간격 조정 */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  /* 그림자 효과 */
  line-height: 1.2;
  /* 제목의 행간 조절 */
  margin: 0;
  /* 여백 초기화 */
}

.userMainFormContainer .banner p {
  font-size: 20px;
  /* 서브 텍스트 크기 */
  color: white;
  margin: 15px 0;
  letter-spacing: 1px;
  /* 글자 간격 조정 */
  line-height: 1.5;
  /* 서브 텍스트의 행간 조절 */
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  /* 그림자 효과 */
}

.userMainFormContainer .create-cake-btn {
  padding: 12px 24px;
  background-color: #ff6f61;
  font-size: 18px;
  border: none;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-transform: uppercase;
  /* 버튼 텍스트를 모두 대문자로 */
  letter-spacing: 1.5px;
  /* 버튼 글자 간격 */
}

.userMainFormContainer .create-cake-btn:hover {
  background-color: #e04e4e;
  transform: scale(1.1);
}

.userMainFormContainer .banner h1,
.userMainFormContainer .banner p,
.userMainFormContainer .create-cake-btn {
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
      0 0 20px rgba(255, 105, 135, 0.6), 0 0 30px rgba(255, 156, 167, 0.6);
  }

  100% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 1),
      0 0 30px rgba(255, 105, 135, 0.8), 0 0 40px rgba(255, 156, 167, 1);
  }
}

.userMainFormContainer .create-cake-btn:active {
  transform: scale(0.95);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

/* 정렬 버튼 스타일 */
.sort-box {
  display: flex;
  justify-content: flex-end;
  /* 버튼을 오른쪽 정렬 */
}

.sort-box button {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  color: #495057;
  padding: 10px 20px;
  margin-left: 10px;
  /* 버튼 간격 추가 */
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
}

.sort-box button:hover {
  background-color: #e2e6ea;
}

.sort-box button.active {
  background-color: #495057;
  color: #ffffff;
}

/* 화살표 스타일 */
.sort-box button::after {
  content: attr(data-sort);
  margin-left: 5px;
  font-weight: bold;
  color: inherit;
  font-size: 12px;
}
.userMainFormContainer .pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.userMainFormContainer .pagination button {
  margin: 0 5px;
  padding: 8px 12px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 17px;
}

.userMainFormContainer .pagination button:hover {
  font-weight: bold;
  color: #ff85a1;
}

.userMainFormContainer .pagination button.active {
  color: #ff85a1;
  font-weight: bold;
}

/* 모바일 화면 (최대 768px) */
@media screen and (max-width: 768px) {
  #user-wrap {
    padding: 10px;
  }

  .userMainFormContainer .banner {
    height: 200px;
  }

  /* 배너 텍스트 크기 조정 */
  .userMainFormContainer .banner h1 {
    font-size: 24px;
    letter-spacing: 1px;
  }

  .userMainFormContainer .banner p {
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .userMainFormContainer .create-cake-btn {
    font-size: 16px;
    padding: 8px 16px;
    letter-spacing: 1px;
  }

  /* 지도 이미지 크기 조정 */
  .userMainFormContainer .map-img {
    display: none;
  }

  /* 지도 버튼 정렬 */
  .userMainFormContainer .sy-user-main-title {
    margin: 50px auto;
    text-align: center;
    /* 텍스트를 수평 가운데 정렬 */
  }

  .userMainFormContainer .map-click button {
    width: calc((100% - 20px) / 7);
    /* 3개의 버튼이 한 줄에 */
    padding: 5px;
    margin: 0 auto;
  }

  /* 카테고리 버튼 크기 조정 */
  .userMainFormContainer .category-box ul {
    max-width: 100%;
    gap: 10px;
  }

  .userMainFormContainer .category-box ul button {
    font-size: 12px;
    width: 75px;
  }

  /* 상품 리스트 그리드 조정 */
  .userMainFormContainer .all-product-item {
    width: calc(100% - 20px);
    margin-bottom: 20px;
  }

  .userMainFormContainer .list_hover_img {
    height: 150px;
  }

  .userMainFormContainer .product-info {
    padding: 5px;
    font-size: 12px;
  }

  /* 정렬 버튼 크기 */
  .sort-box button {
    font-size: 12px;
    padding: 8px 12px;
  }
}
