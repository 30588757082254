/* 주문 정보 섹션 */
.purchasedproductsDetail-info {
    padding: 50px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.purchasedproductsDetail-section {
    margin-bottom: 20px;
    /* 섹션 간 간격 */
    padding: 15px;
    /* 섹션 내부 여백 */
    border: 1px solid #ddd;
    /* 테두리 */
    border-radius: 8px;
    /* 모서리 둥글게 */
    background-color: #f9f9f9;
    /* 섹션 배경색 */
}

.purchasedproductsDetail-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* 텍스트 섹션 */
.purchasedproductsDetail-text {
    flex: 1;
    margin-right: 20px;
}

.purchasedproductsDetail-text h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.purchasedproductsDetail-text h2 {
    font-size: 28px;
    color: #333;
    font-weight: bold;
    margin-bottom: 20px;
}

.purchasedproductsDetail-section h3 {
    font-size: 18px;
    /* 섹션 제목 크기 */
    color: #333;
    margin-bottom: 10px;
    /* 제목 아래 간격 */
    border-bottom: 1px solid #ddd;
    /* 제목 아래 경계선 */
    padding-bottom: 5px;
    /* 제목과 경계선 간격 */
}

.purchasedproductsDetail-basic-info p {
    font-size: 16px;
    /* 텍스트 크기 */
    line-height: 1.8;
    /* 줄 간격 */
    color: #333;
    /* 텍스트 색상 */
    margin: 5px 0;
    /* 항목 간격 */
}

.purchasedproductsDetail-basic-info strong {
    font-weight: bold;
    color: #444;
    /* 라벨 색상 */
}

/* 이미지 섹션 */
/* 카드들이 화면에서 중앙 정렬되도록 설정 */
.purchasedproductsDetail-images {
    display: flex;
    flex-direction: column;
    /* 세로 정렬 */
    align-items: center;
    /* 가로 중앙 정렬 */
    gap: 20px;
    /* 이미지 간 간격 */
    margin-top: 60px;
    /* 위쪽 여백 추가 */
    margin-left: 60px;
}

.purchasedproductsDetail-image-box {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 350px;
    /* 박스 고정 너비 */
    height: 440px;
    /* 박스 고정 높이 */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.purchasedproductsDetail-image-box img {
    width: 100%;
    /* 이미지가 박스 너비에 맞도록 */
    height: 100%;
    /* 이미지가 박스 높이에 맞도록 */
    object-fit: cover;
    /* 비율을 유지하면서 박스에 맞게 자르기 */
    border-radius: 8px;
    max-height: 200px;
    /* 이미지 최대 높이 제한 */
}

.purchasedproductsDetail-image-box p {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
}

/* 사진 및 영상 전달 섹션 */
.purchasedproductsDetail-media-delivery {
    margin-top: 30px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
}

.media-delivery-heading {
    font-size: 18px;
    color: #444;
    margin-bottom: 20px;
    /* 텍스트와 버튼 사이 간격 */
}

.purchasedproductsDetail-media-delivery h2 {
    font-size: 18px;
    color: #444;
    margin-bottom: 15px;
}

.purchasedproductsDetail-media-box {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.purchasedproductsDetail-media-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    width: 200px;
    background-color: #f9f9f9;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 가로 중앙 정렬 */
    justify-content: center;
    /* 세로 중앙 정렬 */
}


.purchasedproductsDetail-media-item h3 {
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.purchasedproductsDetail-media-item p {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.purchasedproductsDetail-media-item button {
    padding: 8px 12px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}


.purchasedproductsDetail-media-item button:hover {
    background-color: #555;
}

.purchasedproductsDetail-media-item .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 14px;
    color: #999;
}

.purchasedproductsDetail-media-item .close-icon:hover {
    color: #666;
}

.purchasedproductsDetail-media-buttons button {
    margin-right: 10px;
    padding: 10px 15px;
    background-color: #0073e6;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.purchasedproductsDetail-media-buttons button:hover {
    background-color: #005bb5;
}

/* 실시간 제작 과정 전송 섹션 */
.purchasedproductsDetail-live-broadcast {
    margin-top: 30px;
    padding: 20px;
    background-color: #333;
    color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
}

.purchasedproductsDetail-live-broadcast h2 {
    font-size: 18px;
    color: white;
    margin-bottom: 15px;
}

.purchasedproductsDetail-live-item {
    border: 1px solid #444;
    border-radius: 8px;
    padding: 15px;
    background-color: #222;
    text-align: center;
    color: white;
    position: relative;
    width: 250px;
    margin: 0 auto;
}

.purchasedproductsDetail-live-item p {
    font-size: 14px;
    margin-bottom: 10px;
}

.purchasedproductsDetail-live-item button {
    padding: 8px 12px;
    background-color: #444;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

.purchasedproductsDetail-live-item button:hover {
    background-color: #666;
}

.purchasedproductsDetail-live-item .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 14px;
    color: #aaa;
}

.purchasedproductsDetail-live-item .close-icon:hover {
    color: #888;
}

.purchasedproductsDetail-status {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.purchasedproductsDetail-status-card {
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 200px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.purchasedproductsDetail-status-card h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.purchasedproductsDetail-status-card p {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
}

.purchasedproductsDetail-status-card button {
    display: block;
    margin: 5px auto;
    padding: 8px 12px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

.purchasedproductsDetail-status-card button:hover {
    background-color: #555;
}


/* 미리보기 섹션 */
.purchasedproductsDetail-preview {
    margin-top: 30px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.purchasedproductsDetail-preview h2 {
    font-size: 18px;
    color: #444;
    margin-bottom: 15px;
}

.purchasedproductsDetail-preview-content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.purchasedproductsDetail-preview-item {
    text-align: center;
    width: 150px;
    position: relative;
}

.purchasedproductsDetail-preview-item img,
.purchasedproductsDetail-preview-item video {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.purchasedproductsDetail-preview-item button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.purchasedproductsDetail-preview-item button:hover {
    background-color: #c0392b;
}

/* 모든 섹션에 공통으로 가운데 정렬을 적용할 수 있는 클래스 */
.purchasedproductsDetail-preview-item .centered-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 수평 중앙 정렬 */
    text-align: center;
    /* 텍스트 중앙 정렬 */
    margin: 10 auto;
    /* 여백 자동 설정으로 중앙 배치 */
}

/* 버튼이 가운데 정렬되도록 추가 스타일 조정 */
.purchasedproductsDetail-preview-item .centered-button {
    display: inline-block;
    width: 80%;
    /* 필요에 따라 너비 조정 */
    max-width: 200px;
    margin: 10px 0;
}


.product-list-main-content .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.product-list-main-content .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 500px;
    max-width: 90%;
    max-height: 80vh;
    /* 화면 높이의 80%로 제한 */
    overflow-y: auto;
    /* 세로 스크롤 활성화 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


/* 스크롤바 전체 영역 */
.product-list-main-content .modal-content::-webkit-scrollbar {
    width: 10px;
    /* 스크롤바 너비 */
    height: 10px;
    /* 가로 스크롤바 높이 */
}

/* 스크롤바 막대 */
.product-list-main-content .modal-content::-webkit-scrollbar-thumb {
    background: #999;
    /* 스크롤바 색상 */
    border-radius: 5px;
    /* 스크롤바 둥글게 */
    border: 2px solid #fff;
    /* 배경과의 간격 */
}

/* 스크롤바 막대에 호버 시 효과 */
.product-list-main-content .modal-content::-webkit-scrollbar-thumb:hover {
    background: #666;
    /* 호버 시 색상 */
}

/* 스크롤 트랙 (스크롤바 배경) */
.product-list-main-content .modal-content::-webkit-scrollbar-track {
    background: #f4f4f4;
    /* 트랙 배경색 */
    border-radius: 5px;
    /* 트랙 둥글게 */
}

/* 스크롤 트랙 끝 (optional) */
.product-list-main-content .modal-content::-webkit-scrollbar-track-piece {
    background: #e0e0e0;
    /* 트랙 끝부분 배경 */
}

.product-list-main-content .modal-actions {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
}

.product-list-main-content .modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.product-list-main-content .modal-actions button:hover {
    background-color: #f0f0f0;
}


.purchasedproductsDetail-preview {
    margin-top: 15px;
    text-align: center;
}

.purchasedproductsDetail-preview img,
.purchasedproductsDetail-preview video {
    max-width: 300px;
    max-height: 200px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.purchasedproductsDetail-preview h4 {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
}


/* 모바일 디바이스 (화면 너비 768px 이하) */
@media screen and (max-width: 768px) {

    /* 전체 컨테이너 중앙 정렬 */
    .purchasedproductsDetail-info,
    .purchasedproductsDetail-media-delivery,
    .purchasedproductsDetail-live-broadcast,
    .purchasedproductsDetail-preview,
    .purchasedproductsDetail-status {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
       
        /* 내부 여백 추가 */
        /* 중앙 정렬 */
        width: 100%;
        /* 화면에 맞게 너비 조정 */
    }

    @media screen and (max-width: 768px) {
        .purchasedproductsDetail-images {
            display: none; /* 모바일에서 숨김 */
        }
    }
    /* 카드 스타일 */

    .purchasedproductsDetail-media-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
    .purchasedproductsDetail-text,
    .purchasedproductsDetail-section,
    .purchasedproductsDetail-media-item,
    .purchasedproductsDetail-status-card {
        width: 100%;
        max-width: 300px;
        /* 카드 최대 너비 */
        margin: 10px auto;
        text-align: center;
    }


    .purchasedproductsDetail-live-item {
        width: 100%;
        max-width: 300px;
        /* 박스 최대 너비 */
        margin: 0 auto;
        text-align: center;
    }



    .purchasedproductsDetail-status {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .modal-content {
        width: 90%;
        max-height: 80vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .purchasedproductsDetail-live-item button,
    .purchasedproductsDetail-status-card button,
    .purchasedproductsDetail-media-item button {
        margin: 5px auto;
        width: 100%;
        max-width: 200px;
        /* 버튼 최대 너비 */
    }


}