.audition-list-container {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  width: 100%;
}

.audition-list-container h2 {
  margin-bottom: 32px;
  font-weight: bold;
  font-size: 1.7em;
}

.audition-filter {
  margin-bottom: 20px;
}

.filter-button {
  padding: 8px 16px;
  border: 1px solid #ff85a1;
  border-radius: 25px;
  background-color: #fff;
  cursor: pointer;
  margin-right: 10px;
  color: #ff85a1;
  transition: all 0.3s ease;
  font-size: 1.1em;
}

.filter-button:hover {
  color: white;
  background-color: #ff85a1;
}

.filter-button.active-ongoing {
  background-color: #ff85a1;
  border: 1px solid #ff85a1;
  color: white;
}

.filter-button.active-end {
  background-color: #ff85a1;
  border: 1px solid #ff85a1;
  color: white;
}

.audition-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.audition-table tr {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  color: #4b5563;
}

.audition-table th,
.audition-table td {
  padding: 16px;
  text-align: center;
  border: none;
}

.audition-table td {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.1em;
}

.audition-table thead tr {
  border-top: none;
  background-color: #e7e7e7;
}

.audition-table th {
  color: #333;
  font-weight: normal;
  font-size: 1.1em;
}

.status-active {
  color: #ff85a1;
  font-weight: bold;
}

.order-link {
  color: #ff85a1;
  text-decoration: none;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid #ff85a1;
  transition: all 0.3s ease;
}

.order-link:hover {
  background-color: #ff85a1;
  color: white;
}

.review-button {
  margin-top: 5px;
  padding: 6px 12px;
  background-color: #ff85a1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.review-button:hover {
  background-color: #ff85a1;
}

.user-audition-mypage-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 15px;
}

.user-audition-mypage-page-button,
.user-audition-mypage-prev-button,
.user-audition-mypage-next-button {
  padding: 8px 12px;
  margin: 0 5px;
  border: none;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  color: #666;
  transition: all 0.3s ease;
  font-size: 17px;
}

.user-audition-mypage-page-button.active {
  color: #ff85a1;
  font-weight: bold;
}

.user-audition-mypage-page-button:hover,
.user-audition-mypage-prev-button:hover,
.user-audition-mypage-next-button:hover {
  font-weight: bold;
}

.go-to-ongoing-detail {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.go-to-ongoing-detail:hover {
  color: #ff85a1;
  text-decoration: underline;
}
