@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR&display=swap');

/* 메인 콘텐츠 영역 */
.product-list-main-content {
    flex-grow: 1;
    background-color: #ffffff;
    border-radius: 16px;
    /* 큰 라운드를 적용하여 부드러운 모서리 */
    padding: 24px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
    /* 더 부드러운 그림자 */
    transition: transform 0.3s;
}

/* 상품 리스트 제목 및 등록하기 버튼을 포함하는 헤더 */
.product-list-main-content .product-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* 상품 리스트 제목 스타일 */
.product-list-main-content .product-list-title {
    font-size: 2em;
    /* 크고 굵은 글씨로 제목 강조 */
    font-weight: 600;
    color: #222;
    /* 더 진한 색상으로 강조 */
}

/* 등록하기 버튼 스타일 */
.product-list-main-content .add-button {
    background-color: #404040;
    /* 세련된 보라색 계열 */
    color: #ffffff;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
    margin-left: 10px;
}

.product-list-main-content .add-button:hover {
    background-color: #c662ff;
    /* 호버 시 더 어두운 보라색 */
    box-shadow: 0 4px 12px rgba(95, 87, 239, 0.4);
    transform: translateY(-3px);
}

/* 상품 테이블 스타일 */
.product-list-main-content .product-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 12px;
    /* 행 간 간격 추가하여 현대적인 느낌 */
    margin-top: 20px;
}

.product-list-main-content .product-table th,
.product-list-main-content .product-table td {
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid #e6e8ec;
}

/* 테이블 헤더 스타일 */
.product-list-main-content .product-table th {
    background-color: #f3f4f6;
    /* 더 밝은 배경색 */
    font-weight: 600;
    color: #333;
    /* 진한 색상 */
    border-radius: 8px 8px 0 0;
    /* 테이블 헤더 모서리 둥글게 */
}

/* 짝수 행과 홀수 행 배경색 변경 (가독성 향상) */
.product-list-main-content .product-table tbody tr {
    transition: background-color 0.3s, box-shadow 0.3s;
}

.product-list-main-content .product-table tbody tr:nth-child(even) {
    background-color: #ffffff;
}

.product-list-main-content .product-table tbody tr:nth-child(odd) {
    background-color: #f9fafb;
    /* 좀 더 세련된 회색 */
}

/* 마우스를 올렸을 때 강조 효과 */
.product-list-main-content .product-table tbody tr:hover {
    background-color: #eef2ff;
    /* 아주 연한 파란색으로 강조 */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

/* 버튼 스타일 */
.product-list-main-content .edit-button,
.product-list-main-content .delete-button {
    padding: 8px 16px;
    border: none;
    
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    margin-right: 10px;
    transition: background-color 0.3s, transform 0.2s;
}

/* 수정 버튼 스타일 */
.product-list-main-content .edit-button {
    background-color: #3f3f3f;
    /* 세련된 민트색 */
    color: #ffffff;
}

.product-list-main-content .edit-button:hover {
    background-color: #059669;
    /* 더 어두운 민트색 */
    transform: translateY(-2px);
}

/* 삭제 버튼 스타일 */
.product-list-main-content .delete-button {
    background-color: #f87171;
    /* 밝은 빨간색 */
    color: #ffffff;
}

.product-list-main-content .delete-button:hover {
    background-color: #e11d48;
    /* 더 진한 빨간색 */
    transform: translateY(-2px);
}

.product-list-main-content .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 5px;
    /* 버튼 간격 */
}

.product-list-main-content .page-button {
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}

.product-list-main-content .page-button.active {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
    border-color: #007bff;
}

.product-list-main-content .page-button:hover {
    background-color: #e0e0e0;
    border-color: #bbb;
}

.product-list-main-content .page-button:disabled {
    background-color: #f1f1f1;
    color: #aaa;
    cursor: not-allowed;
}

.product-list-main-content .pagination button {
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}

.product-list-main-content .pagination button:hover:not(:disabled) {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

.product-list-main-content .status-button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    color: rgb(0, 0, 0);
    font-weight: bold;
}

.product-list-main-content .status-button.visible {
    background-color: #b1ffb0;
    /* 노출 상태의 녹색 버튼 */
}

.product-list-main-content .status-button.hidden {
    background-color: #ff8880;
    /* 미노출 상태의 빨간색 버튼 */
}



/* 반응형 처리 */
@media (max-width: 768px) {
    .product-list-header {
        flex-direction: column;
        /* 좁은 화면에서는 제목과 버튼이 수직으로 배치되도록 설정 */
        align-items: flex-start;
    }

    .add-button {
        margin-top: 10px;
    }

    .product-table th,
    .product-table td {
        padding: 12px;
        /* 좁은 화면에서는 패딩을 줄여 공간 절약 */
    }
}