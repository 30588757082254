/* 찜 목록 컨테이너 */
/* #user-wrap .wishlist-container {
    padding: 20px;
} */

.user-write-main-title {
  margin-bottom: 32px;
  font-weight: bold;
  font-size: 1.8em;
}

/* 검색 바 스타일 */
#user-wrap .wishlist-container .search-bar {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 400px;
}

#user-wrap .wishlist-container .search-input {
  flex: 1 1;
  padding: 10px 15px;
  height: 43px;
  border: 1px solid #999999;
  border-right: none;
  border-radius: 20px 0 0 20px;
  font-size: 14px;
  outline: none;
}

.search-input::placeholder {
  color: #bbbbbb; /* 원하는 색상 코드 */
  opacity: 1; /* 불투명도 설정 (기본값이 0.5일 수 있음) */
}

#user-wrap .wishlist-container .search-button {
  padding: 10px 20px;
  height: 43px;
  background-color: white;
  border: 1px solid #999999;
  border-left: none; /* 왼쪽 테두리 제거 */
  border-radius: 0 25px 25px 0; /* 순서: 좌상 우상 우하 좌하 */
  color: #999999;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* 카테고리 필터 스타일 */
#user-wrap .wishlist-container .category-filters {
  margin-bottom: 30px;
}

#user-wrap .wishlist-container .filter-group {
  margin-bottom: 20px;
}

#user-wrap .wishlist-container .filter-group h3 {
  margin-bottom: 10px;
  color: #374151;
}

/* 상품 그리드 스타일 */
#user-wrap .wishlist-container .products-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin: 30px 0;
}

.products-grid p {
  color: #666;
}

#user-wrap .wishlist-container .product-card {
  text-decoration: none;
  color: inherit;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd; /* 투명한 테두리 추가 */
}

/* #user-wrap .wishlist-container .product-card:hover {
  border-color: #ffe9ee;
} */

#user-wrap .wishlist-container .product-image {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

#user-wrap .wishlist-container .product-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#user-wrap .wishlist-container .product-info {
  padding: 16px;
  background: white;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#user-wrap .wishlist-container .product-info h3 {
  font-size: 1.1em;
  color: #4b5563;
  margin: 0 0 8px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#user-wrap .wishlist-container .product-info p {
  font-size: 1em;
  color: #ff85a1;
  font-weight: bold;
  margin: 0;
}

/* 내가 그린 도안, 찜한 도안 */
#user-wrap .wishlist-container .product-infos {
  padding: 16px;
  background: white;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#user-wrap .wishlist-container .product-infos h3 {
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
  margin: 0 0 8px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#user-wrap .wishlist-container .product-infos p {
  font-size: 1em;
  color: #666;
  margin: 0;
}

/* 페이지네이션 스타일 */
#user-wrap .wishlist-container .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#user-wrap .wishlist-container .page-numbers {
  display: flex;
  gap: 5px;
}

#user-wrap .wishlist-container .page-number,
#user-wrap .wishlist-container .prev-page,
#user-wrap .wishlist-container .next-page {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  color: #666;
  font-size: 17px;
}

#user-wrap .wishlist-container .page-number.active {
  color: #ff85a1;
  font-weight: bold;
}

#user-wrap .wishlist-container .page-number:hover,
#user-wrap .wishlist-container .prev-page:hover,
#user-wrap .wishlist-container .next-page:hover {
  font-weight: bold;
}

/* 반응형 디자인 */
@media (max-width: 1200px) {
  #user-wrap .wishlist-container .products-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  #user-wrap .wishlist-container .products-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  #user-wrap .wishlist-container .filter-options {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  #user-wrap .wishlist-container .products-grid {
    grid-template-columns: 1fr;
  }
}
