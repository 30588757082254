/* 전체 레이아웃 정렬 */
.cake-design-detail {
  display: flex;
  width: 100%;
  /* justify-content: space-between;  */
  align-items: flex-start;
  gap: 20px;
}

/* 메인 이미지 스타일 */
.cake-design-detail-imgs {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px; /* 원하는 최대 너비 설정 */
}

.cake-design-detail-main-img {
  width: 100%;
  height: 500px; /* 고정된 높이 설정 */
  max-width: 500px;
  object-fit: contain; /* 이미지가 지정된 크기에 맞게 비율을 유지하면서 맞춰짐 */
  background-color: #fff; /* 이미지가 작을 경우 배경색 지정 */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
}

.cake-design-detail-sub-imgs {
  display: flex;
  max-width: 500px;
  margin-top: 10px;
  flex-wrap: nowrap;
  gap: 8px;
  justify-content: flex-start;
  scrollbar-width: thin; /* Firefox에서 얇은 스크롤바 */
}

.cake-design-detail-sub-imgs img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
  transition: transform 0.2s;
  flex-shrink: 0;
}

.cake-design-detail-sub-imgs-wrapper {
  overflow-x: hidden; /* 기본적으로 가로 스크롤 숨기기 */
}

.cake-design-detail-sub-imgs-wrapper:hover .cake-design-detail-sub-imgs {
  overflow-x: auto; /* 마우스를 올렸을 때만 가로 스크롤 활성화 */
}

/* Webkit 기반 브라우저 (Chrome, Safari) 스크롤바 스타일 */
.cake-design-detail-sub-imgs::-webkit-scrollbar {
  height: 6px;
}

.cake-design-detail-sub-imgs::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

/* 설명 영역 스타일 */
.cake-design-detail-txt {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
  line-height: 1.6;
  margin-left: 20px;
}

.cake-design-detail-txt h2 {
  font-size: 2em;
  font-weight: bold;
  color: #222;
}

.cake-design-detail-txt h3 {
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
}

.cake-design-detail-txt p {
  font-size: 18px;
  color: #555;
  line-height: 1.5;
  padding-bottom: 10px;
}

/* 버튼 스타일 */
.cake-design-detail-buttons {
  display: flex;
  gap: 10px;
  margin-top: 110px;
  justify-content: flex-end;
}

.cake-design-detail-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.cake-design-detail-buttons button:nth-child(1) {
  background-color: #4f46e5;
  color: white;
}

.cake-design-detail-buttons button:nth-child(2) {
  background-color: #f87171;
  color: white;
}

.cake-design-detail-buttons button:nth-child(3) {
  background-color: #a0aec0;
  color: white;
}

.cake-design-detail-buttons button:hover {
  transform: translateY(-2px);
}
