/* 스타일링을 위한 기본 설정 */

.admin-content-page {
    flex: 1; /* 사이드바를 제외한 나머지 공간 채우기 */
    padding: 20px;
    margin: 0; /* 불필요한 마진 제거 */
    background-color: #ffffff;
    overflow: auto; /* 내부 콘텐츠 스크롤 가능 */
    box-sizing: border-box; /* 패딩 포함한 사이즈 계산 */
    max-width: calc(100% - 240px); /* 사이드바 크기를 제외한 너비 */
}

.admin-content-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.admin-content-header h1{
    font-size: 1.8em;
    color: #1e3a8a;
    font-weight: bold;
}
/* 테이블 스타일 */
.admin-content-page .report-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-content-page .report-table th, .report-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
}

.admin-content-page .report-table th {
    background-color: #f8f8f8;
    color: #333;
    font-weight: bold;
}

.admin-content-page .report-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.admin-content-page .report-table tr:hover {
    background-color: #f1f1f1;
}

/* 삭제 버튼 스타일 */
.admin-content-page .delete-button {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-content-page .delete-button:hover {
    background-color: #e04444;
}
