.header-border {
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #ff85a1;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.header-container {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0px;
  position: relative;
}

.header-container .logo {
  position: absolute;
  left: 24px;
  bottom: 0px;
}

.header-container .logo img {
  max-width: 100px;
}

.header-container .nav-menu {
  width: 100%;
  display: flex;
  justify-content: center;
}

.header-container .nav-menu ul {
  display: flex;
  /* gap: 32px; */
  list-style: none;
  padding: 10px;
  margin: 0;
  align-items: center;
}

.header-container .nav-menu a {
  font-family: "HakgyoansimGeurimilgiTTF-R", sans-serif;
  text-decoration: none;
  color: #333;
  font-size: 1.5em;
  transition: background-color 0.3s ease;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 6px;
  white-space: nowrap;
}

.header-container .nav-menu ul li:not(:last-child)::after {
  content: "🍰";
  display: inline-block;
}

.header-container .user-actions {
  position: absolute;
  right: 30px;
  top: 26px;
  display: flex;
  align-items: center;
}

.header-container .user-actions a.header-link {
  font-family: "HakgyoansimGeurimilgiTTF-R", sans-serif;
  text-decoration: none;
  color: #333;
  font-size: 1em;
  transition: background-color 0.3s ease;
  padding: 8px 0px 8px 12px;
  border-radius: 6px;
  white-space: nowrap;
  display: inline-block;
}

/* userheaderstyle.css에 추가 */
.user-actions {
  display: flex;
  gap: 1rem;
  /* 버튼 사이의 간격 */
  align-items: center;
  margin-top: 3px;
}

.header-link {
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #333;
  transition: all 0.3s ease;
}

.header-link:hover {
  color: #ff85a1;
}

.header-link.primary {
  background-color: #ff85a1;
  color: white;
  border-radius: 4px;
}

.header-link.primary:hover {
  background-color: #ff85a1;
  color: white;
}

.main-usernickname {
  font-family: "HakgyoansimGeurimilgiTTF-R", sans-serif;
  color: #333;
}

/* 모바일에서만 표시 */
@media screen and (max-width: 768px) {
  .header-container {
    justify-content: space-between;
    padding: 15px;
    border-left: none;
    border-right: none;
  }

  .header-container .nav-menu {
    display: none;
    /* 네비게이션 메뉴 숨기기 */
  }

  .header-container .logo {
    display: flex;
    font-size: 10px;
    text-align: center;
    /* 가운데 정렬 */
  }

  .header-container .user-actions {
    position: absolute;
    /* 절대 위치 설정 */
    top: 17px;
    /* 상단 여백 */
    right: 10px;
    /* 오른쪽 여백 */
  }

  .header-link {
    font-size: 16px;
  }
}
