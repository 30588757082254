.user-cake-design-saved-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-end;
  align-items: flex-end;
}

.user-cake-design-saved-list > div {
  position: relative;
}

.user-selected-remove-button {
  position: absolute;
  bottom: 10px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #666;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 50%;
  transition: all 0.2s ease;
  padding: 7px 8px 4px 8px;
}

.user-selected-remove-button:hover {
  color: #ff85a1;
}