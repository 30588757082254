/* Category Filters Styling */
.category-filters {
  margin-top: 20px;
  margin-bottom: 20px;
}

.filter-group {
  margin-bottom: 20px;
}

.filter-group h3 {
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-btn {
  padding: 8px 16px;
  border: 1px solid #ff85a1;
  border-radius: 25px;
  background: white;
  color: #ff85a1;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.1em;
}

.filter-btn:hover {
  background: #ff85a1;
  color: white;
}

.filter-btn.active {
  background: #ff85a1;
  color: white;
  border-color: #ff85a1;
}

/* Writing List Table Styling */
.writing-table {
  width: 100%;
  background: #fff;
  border-collapse: collapse;
  margin-top: 10px;
}

.writing-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.writing-table th,
.writing-table td {
  padding: 16px;
  text-align: center;
  border: none;
}

.writing-table td {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #4b5563;
  font-size: 1.1em;
}

.writing-table thead tr {
  border-top: none;
  background-color: #e7e7e7;
}

.writing-table th {
  color: #333;
  font-weight: normal;
  font-size: 1.1em;
}

.user-write-title:hover {
  text-decoration: underline;
  color: #ff85a1;
  cursor: pointer;
}

/* Action Button Styling */
.j-action-btn {
  padding: 6px 12px;
  font-size: 0.85rem;
  background: white;
  color: #ff85a1;
  border: 1px solid #ff85a1;
  border-radius: 8px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  margin-left: 10px;
  transition: all 0.3s ease;
}

.j-action-btn:hover {
  background: #ff85a1;
  color: white;
}

.j-action-btn.delete-btn {
  background: white;
  border: 1px solid #ff85a1;
  color: #ff85a1;
}

.j-action-btn.delete-btn:hover {
  background: #ff85a1;
  color: white;
}

/* Search Bar Styling */
.j-search-bar {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 400px;
}

.j-search-input2 {
  flex: 1 1;
  padding: 10px 15px;
  height: 43px;
  border: 1px solid #bbbbbb;
  border-right: none;
  border-radius: 20px 0 0 20px;
  font-size: 14px;
  outline: none;
}

.j-search-input2::placeholder {
  color: #bbbbbb; /* 원하는 색상 코드 */
  opacity: 1; /* 불투명도 설정 (기본값이 0.5일 수 있음) */
}

.j-search-input2:focus {
  border-color: #ff85a1;
}

.j-search-button2 {
  padding: 10px 20px;
  height: 43px;
  background: #ff85a1;
  border: none;
  border-radius: 0 20px 20px 0;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.j-search-button2:hover {
  background: #ff85a1;
}

/* Table Column Widths */
.j-writing-table-num {
  width: 10%;
}

.j-writing-table-title {
  width: 40%;
}

.j-writing-table-btn {
  width: 25%;
}

.j-writing-table-date {
  width: 25%;
}

.writing-list {
  margin: 30px 0px;
}