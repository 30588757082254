@charset "UTF-8";

/*내역 상세보기 버튼*/
.product-table .supervision-read-button{
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    margin-right: 10px;
    transition: background-color 0.3s, transform 0.2s;
    background-color: #f3f4f6;
}  
/*취소하기 버튼*/
.product-table .supervision-delete-button{
    padding: 8px 16px;   
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    margin-right: 10px;
    transition: background-color 0.3s, transform 0.2s;
    background-color: #f3f4f6;
}