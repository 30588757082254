/* 전체 챗봇 창 */
.chat-bot-container {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 480px;
    height: 500px;
    background: linear-gradient(135deg, #ffe4ec, #ffdce5);
    /* 부드러운 핑크 그라데이션 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    overflow: hidden;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    font-family: 'Quicksand', sans-serif;
    animation: fadeIn 0.3s ease-in-out;
}

/* 헤더 */
.chat-bot-container .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    font-size: 1.2em;
    font-weight: bold;
    background-color: #ffaad4;
    /* 진한 핑크 */
    color: white;
}

/* 닫기 버튼 */
.chat-bot-container .close-chat {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: #ff6f91;
}

.chat-bot-container .close-chat:hover {
    color: #ff3b6f;
}

/* 챗봇 내부 */
.chat-bot-container .chat-gpt-app {
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: 90%;
}

.chat-bot-container .chat-title {
    text-align: center;
    font-size: 1.8em;
    color: #ff6f91;
    margin-bottom: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-bot-container .chat-title:before {
    content: "🍰";
    margin-right: 8px;
}

/* 메시지 스타일 */
.chat-bot-container .chat-messages {
    flex: 1;
    /* 메시지 영역이 남은 공간을 차지 */
    overflow-y: auto;
    /* 스크롤 활성화 */
    margin-bottom: 5px;
    /* 입력창과 간격 */
    border-radius: 0 0 15px 15px;
}

.chat-bot-container .chat-user-message {
    text-align: right;
    background-color: #ffdce5;
    /* 파스텔 핑크 */
    padding: 10px;
    border-radius: 15px 0 15px 15px;
    margin: 5px;
    display: inline-block;
    max-width: 100%;
    font-size: 1em;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-bot-container .chat-gpt-message {
    text-align: left;
    background-color: #fff5f8;
    /* 밝은 핑크 */
    padding: 10px;
    border-radius: 0 15px 15px 15px;
    margin: 5px;
    display: inline-block;
    max-width: 90%;
    font-size: 1em;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-bot-container .chat-gpt-message::before {
    content: "🍰 YUMMY 봇: ";
    font-weight: bold;
    color: #ff6f91;
}

/* 입력 영역 */
.chat-bot-container .chat-input-container {
    display: flex;
    gap: 10px;
    background-color: #ffe4ec;
    /* 입력창 배경 */
    padding: 10px;
    border-top: 1px solid #ffdce5;
    border-radius: 10px;
}

.chat-bot-container .chat-input {
    flex: 1;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ddd;
    font-size: 1em;
}

.chat-bot-container .chat-send-button {
    background: linear-gradient(135deg, #ff6f91, #ff85a2);
    /* 버튼 그라데이션 */
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chat-bot-container .chat-send-button:hover {
    background: linear-gradient(135deg, #ff3b6f, #ff6f91);
    /* 더 진한 핑크 */
}

.chat-bot-container .chat-user-message,
.chat-bot-container .chat-gpt-message {
    animation: fadeInMessage 0.3s ease-in-out;
}

.chat-bot-container .chat-button {
    display: inline-block;
    margin-top: 10px;
    padding: 12px 20px;
    background-color: #ff6fa5; /* 밝은 핑크 */
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.chat-bot-container .chat-button:hover {
    background-color: #e0558e; /* 진한 핑크 */
}


/* 애니메이션 */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}


.chat-messages::-webkit-scrollbar {
    width: 6px;
}

.chat-messages::-webkit-scrollbar-thumb {
    background-color: #ff6f91;
    /* 핑크 톤 */
    border-radius: 10px;
}

.chat-messages::-webkit-scrollbar-track {
    background: #ffe4ec;
}


@media screen and (max-width: 768px) {

    .chat-bot-container {
        width: 350px;
    }

}