/* 리뷰 관리 컨테이너 */
.vender-reviewlist {
    background-color: #f5f5f7;
    /* 애플 느낌의 밝은 회색 */
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* 부드러운 그림자 */
}

/* 제목 스타일 */
.vender-reviewlist h1 {
    font-size: 28px;
    color: #1d1d1f;
    /* 애플 다크 그레이 */
    font-weight: 600;
    margin-bottom: 20px;
    border-bottom: 2px solid #d1d1d6;
    /* 구분선 */
    padding-bottom: 10px;
}

/* 테이블 스타일 */
.vender-reviewlist table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    /* 테이블 배경 흰색 */
    border-radius: 12px;
    overflow: hidden;
    /* 둥근 테두리 적용 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    /* 부드러운 그림자 */
}

.vender-reviewlist table th,
.vender-reviewlist table td {
    text-align: left;
    padding: 12px 15px;
    /* 셀 여백 */
    font-size: 16px;
    color: #1d1d1f;
    /* 텍스트 색상 */
    border-bottom: 1px solid #e5e5ea;
    /* 구분선 */
}

.vender-reviewlist table th {
    font-weight: 600;
    background-color: #f2f2f7;
    /* 애플 밝은 회색 */
    color: #636366;
    /* 덜 강조된 색상 */
}

.vender-reviewlist table tr:hover {
    background-color: #f9f9f9;
    /* 행 호버 효과 */
    transition: background-color 0.3s ease-in-out;
}

/* 마지막 행 구분선 제거 */
.vender-reviewlist table tr:last-child td {
    border-bottom: none;
}

/* 링크 스타일 */
.vender-reviewlist a {
    color: #007aff;
    /* 애플 블루 */
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
}

.vender-reviewlist a:hover {
    color: #0056d6;
    /* 어두운 애플 블루 */
}

/* 버튼 스타일 */
.vender-reviewlist .action-btn {
    display: inline-block;
    padding: 8px 16px;
    font-size: 14px;
    color: #ffffff;
    background-color: #007aff;
    /* 애플 블루 */
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    transition: background-color 0.3s ease-in-out;
}

.vender-reviewlist .action-btn:hover {
    background-color: #0056d6;
    /* 더 어두운 블루 */
}

/* 상태 표시 */
.vender-reviewlist .status {
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    color: #ffffff;
    border-radius: 12px;
    font-weight: 500;
}

.vender-reviewlist .status.has-review {
    background-color: #34c759;
    /* 애플 그린 */
}

.vender-reviewlist .status.no-review {
    background-color: #ff3b30;
    /* 애플 레드 */
}

.vender-reviewlist .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 5px;
    /* 버튼 간격 */
}

.vender-reviewlist .pagination button {
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}

.vender-reviewlist .pagination button:hover:not(:disabled) {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

/* 반응형 디자인 */
@media screen and (max-width: 768px) {
    .vender-reviewlist h1 {
        font-size: 24px;
    }

    .vender-reviewlist table th,
    .vender-reviewlist table td {
        font-size: 14px;
        padding: 10px;
    }

    .vender-reviewlist .action-btn {
        font-size: 12px;
        padding: 6px 12px;
    }
}


/* 필터 버튼 스타일 */
.vender-reviewlist .filter-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.vender-reviewlist .filter-btn {
    padding: 8px 16px;
    font-size: 14px;
    background-color: #f5f5f7;
    /* 밝은 배경 */
    color: #007aff;
    /* 애플 블루 */
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
}

.vender-reviewlist .filter-btn:hover {
    background-color: #007aff;
    color: #ffffff;
}

.vender-reviewlist .filter-btn.active {
    background-color: #007aff;
    color: #ffffff;
}