#createPage-wrap {
    width: 100%;
    margin: auto;
    padding: 20px;

    border: 2px solid #fcbdbd;
    border-radius: 10px;
}
.sy-create-title{
    text-align: center;
    font-size: 35px;
    color: #202020;
    padding-bottom: 30px;
    border-bottom: 2px inset #fcbdbd ;
    margin-bottom: 10px;
}

#createPage-nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* 오른쪽 끝으로 정렬 */
    align-items: flex-end; /* 아이템들을 오른쪽 끝으로 정렬 */
}
#createPage-nav li {
    position: relative; /* li 요소의 상대 위치 지정 */
    text-align: center; /* 텍스트 중앙 정렬 */
    padding-top: 50px; /* 텍스트와 아이콘 간의 간격 조정 */
    
}
#createPage-nav li span {
    display: block; /* span을 block으로 만들어서 텍스트가 아래로 내려가게 */
}
#createPage-nav li .formIcon {
    position: absolute; /* 아이콘을 절대 위치로 설정 */
    top: 10px; /* 텍스트 바로 위로 아이콘 배치 */
    left: 50%; /* 수평 중앙 정렬 */
    transform: translateX(-50%); /* 정확히 중앙에 오도록 조정 */
}


#createPage-nav button {
    
    padding: 8px 15px;
    border-radius: 5px;
    text-decoration: none;
    
}
.create-sy-flex-box{
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: 45px;
}
.img-box{
    width: 150px;
    height: 150px;
    border: 1px solid #000;
    
}

.create-sy-section {
    margin-bottom: 30px;
    
}
.sy-img-margin-box{
    height: 180px;
}
.banner-flex{
    display: flex;
    flex-direction: flex-end;
    align-items: baseline;
    gap: 3px;
}
.trash-bannerIcon{
    margin-left: 5px;
}


.create-sy-section h3 {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
    font-size: 20px;
}

.input-shop-name{
    border: none;
    border-bottom:1px solid #ddd ;
    outline: none;           /* 클릭 시 외곽선 제거 */
    font-size: 16px;         /* 텍스트 크기 */
    transition: border-color 0.3s; /* 클릭 시 색 변화 애니메이션 */
    margin-top: 5px;
    padding-left: 2px;
}
.create-sy-section .short-input-txt{
    width: 220px;
    border: none;
    border-bottom:1px solid #ddd ;
    outline: none;           /* 클릭 시 외곽선 제거 */
    font-size: 16px;         /* 텍스트 크기 */
    transition: border-color 0.3s; /* 클릭 시 색 변화 애니메이션 */
    margin-top: 5px;
    padding-left: 2px;
    margin-right: 5px;
}


.create-sy-section textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    height: 150px;              /* 원하는 높이로 설정 */
    resize: vertical;           /* 사용자가 세로 방향으로 크기 조절 가능하게 설정 */
}

.preview-container{
    text-align: center;
    padding-left: 5%;
}

.preview {
    width: 130px;
    height: 130px;
    border: 1px solid #ddd;
    border-radius: 5px;
    object-fit: cover;
    display: block;
    margin: 10px 0;
    
}
.section-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    margin-top: 5px;
    max-width: 800px; /* 원하는 고정된 최대 너비를 설정 */
    width: 100%;      /* 컨테이너가 가로로 꽉 차게 설정 */
}
.sy-category-upload {
    flex: 0 0 calc(30% - 30px); /* 부모 컨테이너의 너비에 비례 */
    
    
}
.category-box{
    display: flex;
    justify-content: space-between;

}

.sy-add-category {
    display: inline-block;
    
    
    padding: 5px 20px;
    background-color: #ffffff;
    border: 2px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
}
.page-insert-btn{
    display: flex;
    justify-content: center;
    align-items: center;

}
.sy-apply-button {
    display: block;
    width: 30%;
    padding: 10px;
    background-color: #fbe7fe;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
    
}

.long-input-txt{
    width: 98%;
    
    border: none;
    border-bottom:1px solid #ddd ;
    outline: none;           /* 클릭 시 외곽선 제거 */
    font-size: 16px;         /* 텍스트 크기 */
    transition: border-color 0.3s; /* 클릭 시 색 변화 애니메이션 */
    margin-top: 5px;
    padding-left: 2px;
    margin-right: 5px;
}
