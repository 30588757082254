#user-wrap .order-detail-container2 .order-basic-info {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #bcbcbc;;
}

.dot {
  font-size: 1.4em;
}

#user-wrap .order-detail-container2 .order-date {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

#user-wrap .order-detail-container2 .order-number {
  font-size: 1.1em;
  padding-top: 3px;
  margin-left: 5px;
  color: #666;
}

#user-wrap .order-detail-container2 .order-product-info {
  display: flex;
  gap: 25px;
  padding: 25px;
  background-color: #fff;
  border: 1px solid #bcbcbc;;
  border-radius: 8px;
  margin-bottom: 30px;
  align-items: flex-start;
}

#user-wrap .order-detail-container2 .product-image-container {
  width: 200px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
}

#user-wrap .order-detail-container2 .product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#user-wrap .order-detail-container2 .product-details {
  flex: 1;
  padding: 0px 15px;
}

#user-wrap .order-detail-container2 .product-details h3 {
  margin: 0 0 20px 0;
  font-size: 1.4em;
  color: #333;
  font-weight: bold;
  padding-bottom: 15px;
  border-bottom: 1px solid #333;
}

#user-wrap .order-detail-container2 .detail-item {
  margin: 12px 0;
  display: flex;
  align-items: flex-start;
}

#user-wrap .order-detail-container2 .detail-item .label::before {
  content: "•";
  color: #ff85a1;  /* 연한 핑크색 */
  margin-right: 6px;
  font-size: 1.2em;
}

#user-wrap .order-detail-container2 .detail-item .label {
  min-width: 120px;
  color: #333;
  font-weight: 500;
  flex-shrink: 0;
  font-size: 1.2em;
}

#user-wrap .order-detail-container2 .detail-item .value {
  color: #333;
  flex: 1;
  font-size: 1.2em;
  padding: 10px 0px 0px 20px;
}

/* #user-wrap .order-detail-container2 .detail-item.note {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ffe9ee;
    border-radius: 8px;
    background-color: #fff;
} */

#user-wrap .order-detail-container2 .price {
  color: #ff7292;
  font-size: 1.2em;
}

/* #user-wrap .order-detail-container2 .product-status {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ffe9ee;
    border-radius: 8px;
    background-color: #fff;
} */

#user-wrap .order-detail-container2 .status-label {
  color: #333;
  margin-right: 30px;
  font-size: 1.2em;
}

#user-wrap .order-detail-container2 .status-label::before {
  content: "•";
  color: #ff85a1;  /* 연한 핑크색 */
  margin-right: 6px;
  font-size: 1.2em;
}

#user-wrap .order-detail-container2 .status-value {
  color: #ff7292;
  font-size: 1.2em;
  font-weight: bold;
  margin-left: 23px;
}

#user-wrap .order-detail-container2 .order-section {
  margin-bottom: 30px;
  background: #fff;
  border-radius: 8px;
  padding: 25px 25px 10px 25px;
  border: 1px solid #bcbcbc;;
}

#user-wrap .order-detail-container2 .order-section h3 {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  padding-bottom: 10px;
  border-bottom: 1px solid #333;
}

#user-wrap .order-detail-container2 .info-content p {
  margin: 12px 0;
  display: flex;
  align-items: center;
  line-height: 1.6;
}

#user-wrap .order-detail-container2 .label {
  min-width: 100px;
  color: #333;
  font-size: 1.2em;
  flex-shrink: 0;
}

#user-wrap .order-detail-container2 .value {
  color: #666;
  font-size: 1.2em;
  flex: 1;
}

#user-wrap .order-detail-container2 .delivery-note {
  color: #ff7292;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ffe9ee;
  border-radius: 8px;
  background-color: #fff;
}

#user-wrap .order-detail-container2 .price-summary {
  margin-top: 20px;
  border-radius: 8px;
  background-color: #fff;
}

#user-wrap .order-detail-container2 .price-summary p {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  color: #666;
  font-weight: bold;
}

#user-wrap .order-detail-container2 .price-summary .total {
  font-weight: bold;
  color: #ff7292;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #bcbcbc;;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  #user-wrap .order-detail-container2 .order-product-info {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  #user-wrap .order-detail-container2 .product-image-container {
    width: 100%;
    max-width: 300px;
    height: 300px;
  }

  #user-wrap .order-detail-container2 .detail-item {
    flex-direction: column;
    text-align: left;
  }

  #user-wrap .order-detail-container2 .detail-item .label {
    margin-bottom: 5px;
  }

  #user-wrap .order-detail-container2 .info-content p {
    flex-direction: column;
  }

  #user-wrap .order-detail-container2 .label {
    margin-bottom: 5px;
  }

  #user-wrap .order-detail-container2 .value {
    padding-left: 0;
  }
}
