/* Receiver.css */

.receiver-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
    font-family: 'Arial', sans-serif;
}

.receiver-header {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.receiver-video-wrapper {
    position: relative;
    width: 100%;
    max-width: 800px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    background: #000;
}

.receiver-video {
    width: 100%;
    height: auto;
    display: block;
}

.receiver-button-container {
    margin-top: 20px;
    display: flex;
    gap: 15px;
}

.receiver-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.receiver-button:hover {
    background-color: #0056b3;
}

.receiver-button:active {
    background-color: #003f7f;
}

.receiver-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
