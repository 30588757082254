/* Title and button alignment */
.user-cake-designs-details {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
}

/* Header 전체 정렬 */
.user-design-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px 5px 0px;
}

/* 제목 스타일 */
.user-cake-designs-title {
  font-size: 40px;
  font-weight: bold;
  color: #333;
}

/* 작성 날짜와 버튼 컨테이너 */
.user-design-info {
  display: flex; /* 이 부분이 꼭 필요합니다! */
  align-items: center;
  position: relative;
  top: 5px;
  border-bottom: 1px solid #333;
  margin-bottom: 20px;
  padding-bottom: 10px; /* 텍스트와 border 사이 간격 추가 */
}
/* 날짜 스타일 */
.user-cake-design-write-date {
  font-size: 15px;
  color: #666;
  padding-right: 10px;
}

.user-cake-design-hit {
  font-size: 15px;
  color: #666;
  padding-right: 10px;
}

.user-cake-design-author {
  font-size: 17px;
  color: #666;
}

#cake-design-action-btns {
  margin-top: 30px;
  padding: 20px 20px 20px 30px;
  background-color: #ececec;
  position: relative;
}

.user-action-buttons {
  position: absolute;
  right: 1px;
  top: 0px;
}

.user-action-buttons-tip {
  font-size: 21px;
  color: #555;
}

/* Audition Button */
.user-audition-button {
  font-size: 21px;
  background-color: #ff85a1;
  color: #fff;
  padding: 20px 20px 20px 30px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

/* Main layout for image and text area */
.user-cake-design-detail-area {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  gap: 70px;
}

/* Image Section */
#user-cake-design-detail-imgs-area {
  flex: 0 0 570px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-cake-designs-detail-imgs {
  width: 570px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-cake-designs-detail-imgs > img {
  width: 570px;
  height: 570px;
  object-fit: cover;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 25px;
}

/* Sub Images */
.user-cake-designs-detail-sub-imgs {
  display: flex;
  width: 100%;
  margin-top: 5px;
  gap: 5px;
  overflow-x: hidden; /* 기본 상태에서 스크롤 숨기기 */
  scrollbar-width: thin; /* Firefox에서 얇은 스크롤바 */
}

.user-cake-designs-detail-sub-imgs img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid #ddd;
  flex-shrink: 0; /* 이미지 크기가 줄어들지 않도록 설정 */
  box-sizing: border-box;
  border-radius: 25px;
  background-color: white;
}

.user-cake-design-sub-image.selected {
  border-color: #333;
}

.user-cake-designs-detail-sub-imgs:hover {
  overflow-x: auto; /* 마우스를 올렸을 때만 가로 스크롤 표시 */
}

/* Webkit 기반 브라우저 (Chrome, Safari) 스크롤바 스타일 */
.user-cake-designs-detail-sub-imgs::-webkit-scrollbar {
  height: 6px; /* 스크롤바 높이 조정 */
}

.user-cake-designs-detail-sub-imgs::-webkit-scrollbar-thumb {
  background-color: #ccc; /* 스크롤바 색상 */
  border-radius: 10px;
}

/* Text Area */
#user-cake-design-detail-txt-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.user-cake-designs-detail-txt > div {
  flex: 1; /* 자식 div들이 동일한 크기를 차지하도록 설정 */
}

/* 설명 텍스트 스타일 */
.user-cake-designs-detail-txt {
  color: #555;
  line-height: 1.6;
  /* overflow-y: auto; */
}

.user-cake-designs-detail-txt h2 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 2px;
}

.user-cake-designs-detail-txt p {
  font-size: 21px;
  margin-bottom: 20px;
  word-wrap: break-word;
}

/* 찜 버튼 스타일 */
.user-favorite-section {
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.user-favorite-button {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: none;
  border-radius: 25px;
  padding: 6px 10px;
  cursor: pointer;
  font-weight: bold;
}

.users-heart-icon {
  font-size: 30px;
  color: #ff85a1;
  margin-right: 10px;
}

.users-favorite-count {
  font-size: 30px;
  color: #333;
}

.user-control-section {
  float: right;
}

/* cakes Section */
.user-cakes-section {
  text-align: left;
  margin-top: 25px;
  padding: 25px 0px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.user-cakes-section h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 16px;
}

.user-cakes-list {
  display: flex;
  gap: 12px;
  justify-content: flex-start;
}

.user-cakes-item {
  background-color: #f9f9f9;
  padding: 15px 25px;
  border: 2px solid #ddd;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
}

.vender-use-name {
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 17px;
}

.vender-sub-txt {
  margin-top: 5px;
  color: #777777;
}

/* Edit, Delete Buttons */
.user-cake-action-buttons {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.user-cake-edit-button {
  margin: 0px 7px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 25px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.user-cake-edit-button:hover {
  color: white;
  background-color: #ff85a1;
}

.user-cake-delete-button {
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 25px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.user-cake-delete-button:hover {
  color: white;
  background-color: #ea4335;
}

.user-cake-edit-button {
  color: #333;
}

.user-cake-delete-button {
  color: #333;
}

/* Review Section */
.user-review-section {
  margin-top: 60px;
  text-align: left;
  border-top: 1px solid #000;
}

.user-review-section h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  padding-top: 20px;
}

/* Review Item */
.user-cake-designs-review-item {
  padding: 20px;
  margin-top: 8px;
  border-radius: 10px;
  border: 2px solid #ddd;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Review Header */
.user-review-header {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #666;
  padding-bottom: 5px;
  border-bottom: 1px solid #e8e8e8;
}

.user-review-email-id {
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
}

.user-review-date {
  font-size: 1.1em;
  color: #666;
}

.user-review-stars {
  color: gold;
  font-size: 1.2em;
  margin-left: auto; /* 별점을 오른쪽 끝으로 정렬 */
}

/* Review Body */
.user-review-body {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-top: 10px;
}

/* Review Image */
.user-cake-designs-review-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
}

/* Review Text */
.user-review-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
}

.bakery-name {
  font-size: 1.4em;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.bakery-name:hover {
  color: #ff85a1;
}

.cake-name {
  font-size: 1.3em;
  color: #666;
  cursor: pointer;
}

.cake-name:hover {
  color: #ff85a1;
}

.review-content {
  margin-top: 8px;
  font-size: 1.2em;
  color: #555;
}

.no-review-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  color: #999;
  font-size: 14px;
  width: 150px; /* 이미지와 동일한 크기 */
  height: 150px;
  border: 1px dashed #ccc;
  text-align: center;
}
