@charset "UTF-8";
.vender-container {
    
    min-height: 100vh;
}
.vender-insert-audition-wrap{
    max-width: 100%;
    width: 100%;
    border: 3px solid #ddd;
    border-radius: 10px;
    background-color: #fff; /* 내부 콘텐츠 영역의 흰색 배경 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 20px;
}
.insert-h1-box{
    width: 100%;
    padding:10px;
    border-bottom: 3px solid #000;
    margin-bottom: 20px;
}
.vender-insert-audition-wrap h1{
    font-size: 40px;
    font-weight: bold;
}
.insert-search-img-box{
    width: 100%;
    padding: 10px;
    background-color: aquamarine;
    display: flex;
    justify-content: space-between;
}
.insert-h2-box{
    width: 100%;
    padding: 10px;
    border-bottom: 2px solid #c5c5c5;
    padding-bottom: 10px;
}



.insert-h2-box h2{
    font-size: 20px;
    font-weight: bold;
}
.insert-search-img-box h2{
    font-size: 20px;
    
}
.insert-search-img-box input{
    width: 250px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px 8px;
}
.insert-recipt-box{
    display: flex;
    justify-content: space-between;
   /* border-bottom:1px solid #000 ;*/
   margin-bottom: 20px;

}
.insert-recipt-box .insert-audition-right-box{
    width: 50%;
    height: 350px;
    overflow-y: auto;  /* 높이 초과 시 수직 스크롤 생김 */

    
}
.appeal-design-text{
    padding: 5px 10px;
   /* border: 1px solid #ddd;*/
    border-right: none;
    color: rgb(50, 50, 50);
}


.appeal-design-text .orderTitle{
    font-size: 16px;
    font-weight: bold;
}
.appeal-design-text-sy ul{
    padding-left: 10px;
    padding-top: 15px;
}
.appeal-design-text-sy ul li:not(:first-child){
    padding-top: 10px;
}
.appeal-design-text-sy ul li{
    position: relative; /* ::before를 사용할 수 있도록 설정 */
    padding-left: 13px; /* 점과 텍스트 간격 조정 */
}
.appeal-design-text-sy li:not(:last-child)::before {
    content: "•"; /* 점을 추가 */
    position: absolute;
    left: 0; /* 리스트 항목의 왼쪽에 점을 배치 */
    top: 0.5; /* 텍스트와 수평 정렬 */
    color: #000000; /* 분홍색 점 */
    font-size: 20px; /* 점의 크기 */
}



.insert-recipt-box .insert-audition-left-box{
    width: 50%;
    height: 430px;
    overflow-y: auto;  /* 높이 초과 시 수직 스크롤 생김 */
}
.insert-vender-audition-input-photo-box{
    width: 100%;
    height: 300px;
    /*border: 1px solid #ddd;*/
    padding: 10px 15px;
    border-left: none;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.insert-vender-audition-input-photo-box .input-photo-box-line{
    border: hidden;
    height: 85%;
    width: 85%;
}

.insert-recipt-box .insert-audition-left-box{
    width: 50%;
    
}
.Sso-order-img{
    width: 100%;
    height: 100%;
}

/*요청사항박스*/
.appeal-design-text-RequestedTerm{
    border: 2px solid #ffdbdb;
    border-radius: 10px;
    height: 150px;
    padding: 5px;
    overflow-y: auto;  /* 높이 초과 시 수직 스크롤 생김 */

}

.appeal-design-text-RequestedTerm-vender{
    border: 2px solid #d0d0d0;
    border-radius: 10px;
    height: 150px;
    padding: 5px;
    overflow-y: auto;  /* 높이 초과 시 수직 스크롤 생김 */
    margin-right: 20px;
}

.insert-product-alllist{
    margin-top: 10px;
    
}

/* ///////////////////*/
.insert-product-alllist-flex-box{
    display: flex;
    justify-content: space-between;

}
/*
.flex-box-left-product{
    background-color: aquamarine;
    width: 50%;
    border: 1px solid #ddd;
    padding: 10px 15px;
    height: 300px;
}

.flex-box-right-option{
    background-color: bisque;
    width: 50%;
    border: 1px solid #ddd;
    padding: 10px 15px;
    height: 300px;
}
    */
.choose-flex-box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    
}

.choose-flex-box .insert-price-audition{
    border-radius: 10px;
    text-align: center;
}

.insert-price-won{
    font-size: 20px;
}

.choose-flex-box button{
    padding: 5px 20px;
    border-radius: 10px;
    border: 1px solid #ddd;
    cursor: pointer;
}
.choose-flex-box input{
    width:85%;
    padding: 5px 5px;
}
.insert-btn-box{
    margin-top: 10px;
    display: flex;
    justify-content: center;

    padding-top: 15px;
}
.audition-vender-insert-btn{
    width: 300px;
    padding: 8px 15px;
    text-align: center;
    border-radius: 30px;
    border: 1px solid #ddd;

    font-size: 20px;
    cursor: pointer;
    background-color:black;
    color: #fff;
    display: flex;
    justify-content: center;
    text-align: center;
}

.insert-audition-pick{
    color: rgb(60, 64, 255);
}