/* 핑크톤 컬러 변수 추가 */
:root {
    --main-pink: #ff85a1;
    --light-pink: #e7e7e7;
    --hover-pink: #ff85a1;
}

.status-overview h3 {
    font-size: 1.15em;
    color: #555;
}

/* Order List Styles */
#user-wrap .order-list .mypage-order-table {
    width: 100%;
    border-collapse: separate;  /* 변경 */
    border-spacing: 0;         /* 추가 */
    margin-top: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);  /* 추가 */
}

#user-wrap .mypage-order-table th,
#user-wrap .mypage-order-table td {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    border-right: none;             /* 변경 */
    border-left: none;              /* 변경 */
    text-align: center;
    vertical-align: middle;
}

#user-wrap .mypage-order-table th {
    background: var(--light-pink);
    color: #333;
}

/* 각 컬럼 너비 지정 */
#user-wrap .mypage-order-table th:nth-child(1) { /* 등록 날짜 */
    width: 15%;
}

#user-wrap .mypage-order-table th:nth-child(2) { /* 제품명 */
    width: 25%;
}

#user-wrap .mypage-order-table th:nth-child(3) { /* 주문상태 */
    width: 20%;
}

#user-wrap .mypage-order-table th:nth-child(4) { /* 제작업장/시간 */
    width: 15%;
}

#user-wrap .mypage-order-table th:nth-child(5) { /* 관리 */
    width: 35%;
}

#user-wrap .mypage-order-table td {
    color: #4B5563;
    font-size: 1.1em;
}

#user-wrap .mypage-order-table .clickable-status {
    color: var(--main-pink);
    cursor: pointer;
    transition: color 0.2s;
}

#user-wrap .mypage-order-table .clickable-status:hover {
    text-decoration: underline;
}

/* 관리 열의 스타일 수정 */
#user-wrap .mypage-order-table td:last-child {
    text-align: left;        /* 왼쪽 정렬로 변경 */
    padding: 10px 30px;
    position: relative;
    min-width: 200px;
}

#user-wrap .mypage-order-table .action-buttons {
    display: flex;
    justify-content: flex-start;  /* 왼쪽 정렬로 변경 */
    gap: 10px;
    align-items: center;
}

/* 버튼 기본 스타일 */
#user-wrap .action-btn {
    padding: 8px 10px;
    font-size: 12px;
    background-color: white;
    color: var(--main-pink);
    border: 1px solid var(--main-pink);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: none;
    font-size: 0.85em;
}

#user-wrap .action-btn:hover {
    background: var(--main-pink);
    color: white;
}

#user-wrap .order-status-label {
    font-size: 0.7em;
    color: #4B5563;
}

#user-wrap .status-arrow {
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
    color: #9CA3AF;
    font-size: 12px;
}

/* Status Description Table */
#user-wrap .status-description {
    margin-bottom: 30px;
}

#user-wrap .status-overview .description-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

#user-wrap .status-overview .description-table th,
#user-wrap .status-overview .description-table td {
    padding: 16px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #ddd;  /* 모든 셀에 테두리 추가 */
    background: white;
}

#user-wrap .status-overview .description-table th {
    background: var(--light-pink); /* 연한 핑크 배경 */
    color: #333;
    font-size: 1.1em;
    border-top: 1px solid #ddd;    /* 회색으로 변경 */
    border-bottom: 1px solid #ddd;  /* 회색으로 변경 */
}

/* 숫자 행 스타일 */
#user-wrap .status-overview .description-table tr:nth-child(1) td {
    font-size: 2.2em;
    color: var(--main-pink);
    border-bottom: 1px solid #ddd;  /* 추가: 숫자 아래 구분선 */
}

/* 설명 텍스트 행 스타일 */
#user-wrap .status-overview .description-table tr:nth-child(2) td {
    color: #4B5563;
    font-size: 1.0em;
    padding: 12px 0px;
    border-bottom: 1px solid #ddd;  /* 회색으로 변경 */
}

/* Search Section Styles */
#user-wrap .order-search {
    margin: 30px 0;
}

#user-wrap .date-filter {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

#user-wrap .date-filter input[type="date"] {
    padding: 10px 14px;         /* 변경 */
    border: 1px solid #ddd;     /* 변경 */
    border-radius: 4px;
    font-size: 1.0em;
    background-color: white;    /* 추가 */
}

#user-wrap .date-filter input[type="date"]:focus {
    border-color: var(--main-pink);
    outline: none;
}

#user-wrap .period-btn {
    padding: 9px 14px;
    background: white;          /* 변경 */
    border: 1px solid #ddd;     /* 변경 */
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 1.1em;
    color: #6B7280;
}

#user-wrap .period-btn:hover {
    background: var(--main-pink);
    color: white;
    border-color: var(--main-pink);
}

#user-wrap .notice {
    font-size: 1.1em;
    color: #6B7280;
    margin-top: 15px;            /* 변경 */
    padding: 15px;               /* 추가 */
    background: #f0f0f0;         /* 추가 */
    border-radius: 8px;          /* 추가 */
    border-left: 4px solid var(--main-pink);  /* 추가 */
}

#user-wrap .notice p {
    margin: 8px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
    #user-wrap .order-status-container {
        flex-wrap: wrap;
        gap: 20px;
    }

    #user-wrap .status-item {
        flex: 0 0 calc(50% - 10px);
    }

    #user-wrap .status-arrow {
        display: none;
    }

    #user-wrap .mypage-order-table td {
        padding: 12px 8px;
        font-size: 0.85em;
    }

    #user-wrap .action-btn {
        padding: 6px 12px;
        font-size: 0.85em;
    }
}

/* Order Detail Styles */
.order-detail-container {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
}

.header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #e5e7eb;
}

.order-title {
    font-size: 1.5em;
    color: #333;
    margin: 0;
}

.back-to-list {
    padding: 10px 20px;            /* 변경 */
    background: white;             /* 변경 */
    border: 1px solid var(--main-pink);  /* 변경 */
    color: var(--main-pink);       /* 추가 */
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.back-to-list:hover {
    background: var(--main-pink);
    color: white;
}

.order-info {
    background: var(--light-pink);  /* 변경 */
    padding: 20px;                  /* 변경 */
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);  /* 추가 */
}

.order-info p {
    margin: 5px 0;
    color: #4B5563;
}

.cake-media-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.video-container, .photo-container {
    width: 100%;
}

.video-container h3, .photo-container h3 {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 2px solid #333;
}

.video-wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.cake-video {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.photo-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 15px;
}

.cake-photo {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s;
}

.cake-photo:hover {
    transform: scale(1.02);
}

@media (max-width: 768px) {
    .photo-gallery {
        grid-template-columns: 1fr;
    }

    .cake-photo {
        height: 250px;
    }
}

.user-order-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.user-order-pagination button {
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 12px;
    margin: 0 5px;
    color: #333;;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.user-order-pagination button.active {
    color: var(--main-pink);
    font-weight: bold;
}

.user-order-pagination button:hover {
    font-weight: bold;
}

.confirm-receipt-btn {
    margin-left: 10px;
    font-size: 0.85em;
    padding: 8px 10px;
    color: var(--main-pink);
    border: 1px solid var(--main-pink);
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
}

.confirm-receipt-btn:hover {
    background-color: var(--main-pink);
    color: white;
}