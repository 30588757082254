@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR&display=swap');

/* 전체 컨테이너 */
.vender-container {
    display: flex;
    min-height: 100vh;
    font-family: 'IBM Plex Sans KR', sans-serif;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    /* 가로 스크롤 방지 */
    align-items: flex-start;
    padding-top: 10px;
    background-color: whitesmoke;
}

/* 내부 콘텐츠 래퍼 */
.vender-content-wrapper {
    display: flex;
    max-width: 1700px;
    width: 100%;
    position: relative;
    margin: 0 auto;
    overflow-y: hidden;
    overflow-x: hidden;
    /* 내부 콘텐츠 초과 방지 */
}


/* 사이드바 스타일 */
.vender-sidebar {
    width: 250px;
    /* 기존 250px에서 300px로 크기 조정 */
    background-color: black;
    color: #ffffff;
    height: 100vh;
    padding: 20px;
    position: fixed;
    /* 화면에서 고정 위치 설정 */
    top: 20px;
    box-shadow: 2px 0 5px rgba(141, 138, 123, 0.1);
    overflow-y: auto;
    border-radius: 16px;
    z-index: 10;
}


/* 사이드바 프로필 영역 */
.vender-profile {
    text-align: center;
    margin-bottom: 30px;
}

.vender-profile img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

/* 사이드바 메뉴 스타일 */
.vender-menu {
    list-style: none;
    padding: 0;
}

.vender-menu li {
    padding: 15px 10px;
    cursor: pointer;
    transition: background 0.3s;
    font-weight: 500;
    color: #ffffff;
    border-radius: 8px;
}

.vender-menu li.active,
.vender-menu li:hover {
    background-color: #444444;
    color: #ffffff;
}

/* 로그아웃 버튼 */
.vender-sidebar .exit-button {
    margin-top: 30px;
    background: #ff7777;
    color: #ffffff;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    transition: background 0.3s;
}

.vender-sidebar .exit-button:hover {
    background: #e74c3c;
}

/* 메인 콘텐츠 영역 */
.vender-content {
    flex-grow: 1;
    margin-left: 265px;
    /* 사이드바 너비만큼 여백 설정 */
    border-radius: 16px;
    font-family: 'IBM Plex Sans KR', sans-serif;
    min-height: calc(100vh - 40px);
    overflow-x: hidden;
    /* 내부 콘텐츠 초과 방지 */
}

.vender-container.sidebar-open .vender-content {
    margin-left: 250px;
    /* 사이드바가 열렸을 때 콘텐츠 밀림 */
    transition: margin-left 0.3s ease-in-out;
}

/* 모바일 디바이스 스타일 */
@media screen and (max-width: 768px) {
    .hamburger-menu {
        display: block; /* 모바일에서 보이도록 설정 */
    }
    /* 전체 컨테이너 */
    .vender-container {
        flex-direction: column;
    }

    /* 사이드바 */
    .vender-sidebar {
        position: fixed;
        top: 0;
        left: -250px; /* 기본적으로 숨김 */
        width: 250px;
        height: 100%;
        background-color: #333;
        color: #fff;
        transition: all 0.3s ease-in-out; /* 부드러운 애니메이션 */
        z-index: 1000;
    }
    
    .vender-sidebar.open {
        left: 0; /* 열림 상태에서 화면 안으로 이동 */
    }
    /* 메인 콘텐츠 */
    .vender-content {
        margin-left: 0;
        /* 모바일에서는 마진 제거 */
    }
    .hamburger-menu {
        position: fixed;
        top: 20px;
        left: 20px;
        background-color: #333;
        color: #fff;
        padding: 10px 15px;
        border: none;
        cursor: pointer;
        z-index: 2000; /* 사이드바보다 위 */
        border-radius: 5px;
        font-size: 18px;
    }
    
    .hamburger-menu:hover {
        background-color: #555;
    }
    .parent-container.sidebar-open .main-content {
        margin-left: 250px; /* 사이드바 열렸을 때 콘텐츠 밀림 */
        transition: margin-left 0.3s ease-in-out;
    }

    .vender-content-wrapper {
        transition: margin-left 0.3s ease-in-out;
    }
    
    .vender-content-wrapper.sidebar-open {
        margin-left: 250px; /* 사이드바 열림 상태에서 콘텐츠 밀림 */
    }
}