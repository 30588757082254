.debate-insert-main {
  max-width: 1000px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 60px 50px;
  margin: 0 auto;
  border: 1px solid #dddddd;
  transition: all 0.3s;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.03);
}

/* 타이틀 스타일 */
.debate-title-container {
  text-align: center;
  margin-bottom: 30px;
}

.debate-insert-title {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  display: inline-block;
  position: relative;
  padding-bottom: 15px;
}

.debate-insert-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #ff85a1;
  border-radius: 2px;
}

.debate-insert-list {
  width: 100%;
  margin: 0 auto;
}

/* Form Group Style */
.debate-insert-list-group {
  margin-bottom: 35px;
}

.debate-insert-list-group label {
  display: block;
  font-size: 1.1em;
  color: #333;
  font-weight: 600;
  margin-bottom: 12px;
}

/* Image Insert Section */
.debate-insert-image-section {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 20px 0;
}

.debate-insert-image-option {
  width: 300px;
  min-height: 200px;
  border: 2px dashed #cacaca;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background-color: #f1f1f1;
  transition: all 0.3s ease;
}

.debate-insert-image-option:hover {
  border-color: #ff85a1;
  background-color: #fff;
}

/* Image Container */
.debate-insert-image-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.debate-insert-inserted-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
}

/* Common Button Styling */
.debate-insert-upload-btn,
.debate-insert-modal-btn {
  width: 80%;
  padding: 12px 20px;
  background-color: #fff;
  color: #666;
  border: 1px solid #dddddd;
  border-radius: 25px;
  cursor: pointer;
  font-size: 0.95em;
  font-weight: 500;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.debate-insert-upload-btn:hover,
.debate-insert-modal-btn:hover {
  background-color: #ff85a1;
  color: white;
  border-color: #ff85a1;
}

.debate-insert-delete-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #666;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 1.2em;
}

.debate-insert-delete-btn:hover {
  color: #e74c3c;
}

/* Hidden File Input */
.debate-insert-image-upload-input {
  display: none;
}

/* Input Text Field */
.debate-insert-input-text {
  width: 100%;
  padding: 16px;
  font-size: 1em;
  border: 1px solid #dddddd;
  border-radius: 12px;
  color: #444;
  background-color: #f1f1f1;
  transition: all 0.3s ease;
}

.debate-insert-input-text:focus {
  outline: none;
  border-color: #ff85a1;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(255, 133, 161, 0.1);
}

.debate-insert-input-text::placeholder {
  color: #999;
}

/* Textarea specific styles */
.debate-insert-input-text#debate-insert-content {
  height: 250px;
  resize: none;
  line-height: 1.6;
}

/* Button Styles */
.debate-insert-list-btn-group {
  text-align: center;
}

.debate-insert-submit-button {
  width: 25%;
  padding: 15px 0;
  background-color: #ff85a1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1em;
  font-weight: 500;
  display: inline-block;
  margin: 40px 10px;
  cursor: pointer;
}

.debate-insert-cancel-button {
  width: 25%;
  padding: 15px 0;
  background-color: #ffffff;
  color: #666666;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  font-size: 1em;
  font-weight: 500;
  display: inline-block;
  margin: 40px 10px;
  cursor: pointer;
}